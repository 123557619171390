import { render, staticRenderFns } from "./integrationsApp.vue?vue&type=template&id=263ab3d8&scoped=true&"
import script from "./integrationsApp.vue?vue&type=script&lang=js&"
export * from "./integrationsApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263ab3d8",
  null
  
)

export default component.exports