<template>
    <div id="myFooter">
        <footer class="v-footer p-0 container v-sheet theme--light" id="footerApp" data-booted="true">
            <div class="footerAll w-100">
                <div class="row px-4 px-sm-0 py-2 mb-9 signFooter">
                    <div class="col-12 col-sm-3">
                        <h5>{{ $t('message.company') }}</h5>
                        <div class="mt-5">
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('aboutus')">{{ $t('message.aboutUs') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('betool')">{{ $t('message.whyBeTool') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('noCode')">{{ $t('message.whatIsNocode') }}</a></div>
<!--                          a traduire-->
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('betoolTrust')">be<span style="color: #f300d3">T</span>ool <span style="color: #f300d3">T</span>rust</a></div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <h5>{{ $t('message.supportAide') }}</h5>
                        <div class="mt-5">
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('contact#contact')">{{ $t('message.technicalSupport') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('contact#contact')">{{ $t('message.salesSupport') }}</a></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <h5>{{ $t('message.productTools') }}</h5>
                        <div class="mt-5">
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('services')">{{ $t('message.services') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('integrations')">{{ $t('message.applicationsPlugins') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('tools#projets')">{{ $t('message.projectManagement') }}</a></div>
                            <div class="linkBoxF"><a class="linkFooter" @click="navigateToRefresh('pricing')">{{ $t('message.pricing') }}</a></div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <h5>{{ $t('message.legal') }}</h5>
                        <div class="mt-5">
                            <div class="linkBoxF" @click="navigateToRefresh('DMCA')"><a class="linkFooter">{{ $t('message.dmca') }}</a></div>
                            <div class="linkBoxF" @click="navigateToRefresh('CGU')"><a class="linkFooter">{{ $t('message.CGU') }}</a></div>
                            <div class="linkBoxF" @click="navigateToRefresh('politiqueConfidentialite')"><a class="linkFooter">{{ $t('message.privacyPolicy') }}</a></div>
                        </div>
                    </div>
                </div>
                <div class="menuFooter py-2 d-none d-sm-block">
                    <div class="d-flex mt-3">
                        <div class="mr-1" style="width: 225px; padding-top: 5px;">
                            <v-text-field :rules="rules" v-model="emailFreeTrial"  class="vTextFieldCustom " :placeholder="$t('message.emailPlaceholder')" outlined dense></v-text-field>
                        </div>
                        <div class="ml-1">
                            <v-btn :disabled="!validMail" :href="'https://application.betool.fr/register?mode=freeTrial&email='+emailFreeTrial"  outlined color="primary">{{ $t('message.tryFree') }}</v-btn>
                            <div class="noteBtn"><p class="d-inline-block mr-2">📢</p> {{ $t('message.noCreditCard') }}</div>
                        </div>
                        <v-spacer></v-spacer>
                    </div>
                </div>
                <div class="ssfooter py-2">
                    <div class="container pl-0 pr-0 d-block d-sm-flex align-items-center">
                        <div class="socialLink text-center">
                            <div class="iconSocial Link d-inline-block"><a target="_blank" href="https://www.facebook.com/people/Be-tool/100092438058112/"><i class="fa-brands fa-facebook"></i></a></div>
                            <div class="iconSocial Link d-inline-block"><a target="_blank" href="https://www.linkedin.com/company/betoolapp770/"><i class="fa-brands fa-linkedin"></i></a></div>
                            <div class="iconSocial Link d-inline-block"><a target="_blank" href="https://www.youtube.com/@beTool-jv2mk"><i class="fa-brands fa-youtube"></i></a></div>
                        </div>
                        <div class="spacer"></div>
                        <div class="rowLink text-center">
                            <a class="linkFooter mr-2" @click="navigateToRefresh('CGU')">{{ $t('message.termsOfUse') }}</a>
                            <a class="linkFooter mr-2" @click="navigateToRefresh('politiqueConfidentialite')">{{ $t('message.privacyPolicy') }}</a>
                        </div>
                        <div class="spacer"></div>
                        <div class="py-2 reservFooter text-center">@ 2023 beTool. — <strong>{{ $t('message.allRightsReserved') }}</strong></div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "footerApp",
    data: () => ({
        emailFreeTrial:'',
        rules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
    }),
    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },
    },
    computed: {
        validMail() {
            return !!this.emailFreeTrial && /.+@.+\..+/.test(this.emailFreeTrial);
        }
    }
}
</script>

<style scoped>

</style>
