<template>
    <v-dialog class="mt-16" v-model="modalLeadSync" hide-overlay transition="dialog-bottom-transition" persistent
              max-width="400">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="resetDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn :disabled="!valid" dark text @click="submit">{{ $t('message.leadRequ') }}</v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn :disabled="!valid" dark text @click="submit">Soumettre</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card>
            <v-container>
                <v-form ref="form" v-model="valid" @submit.prevent="submit">
                    <v-text-field v-model="form.companyName" :rules="nameRules" :label="$t('message.companyName')" required></v-text-field>
                    <v-textarea :label="$t('message.problemDescriptionL')" required v-model="form.helpRequest" :rules="requestRules" clearable clear-icon="mdi-close-circle"></v-textarea>
                    <v-text-field v-model="form.phoneNumber" :rules="phoneRules" :label="$t('message.phoneContact')" required></v-text-field>
                    <v-text-field v-model="form.email" :rules="emailRules" :label="$t('message.emailContact')" required></v-text-field>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "modalLead",
    data() {
        return {
            valid: true,
            form: {
                companyName: '',
                helpRequest: '',
                phoneNumber: '',
                email: '',
            },
            nameRules: [v => !!v || 'Le nom de l\'entreprise est requis',
            ],
            requestRules: [v => !!v || 'Le sujet de la demande est requis',
            ],
            phoneRules: [v => !!v || 'Le numéro de téléphone est requis',
            ],
            emailRules: [v => !!v || 'L\'email est requis', v => /.+@.+/.test(v) || 'L\'email doit être valide',
            ],



            // nameRules: [
            //     v => !!v || $t('formValidation.companyNameRequired'),
            // ],
            // requestRules: [
            //     v => !!v || $t('formValidation.requestSubjectRequired'),
            // ],
            // phoneRules: [
            //     v => !!v || $t('formValidation.phoneNumberRequired'),
            // ],
            // emailRules: [
            //     v => !!v || $t('formValidation.emailRequired'),
            //     v => /.+@.+/.test(v) || $t('formValidation.emailInvalid'),
            // ],
        };
    },
    props: {
        modalLead: {
            type:Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        modalLeadSync: {
            get() {
                return this.modalLead;
            },
            set(val) {
                this.$emit("update:modal-lead", val);
            }
        }
    },
    methods: {
        resetDialog() {
            this.form.companyName = '';
            this.form.helpRequest = '';
            this.form.helpRequest = '';
            this.form.phoneNumber = '';
            this.form.helpRequest = '';
            this.form.email = '';
            this.modalLeadSync = false;
        },
        submit() {
            if (this.$refs.form.validate()) {
                axios.post('https://www.objectif-crm.com/supportTech/indexSupport.php', this.form)
                    .then(() => {
                        this.$refs.form.reset();
                        this.modalLeadSync = false;
                        // handle server response if needed
                    })
                    .catch(error => {
                        console.error(error);
                        // handle errors
                    });
            }
        },
    },
}
</script>

<style scoped>

</style>