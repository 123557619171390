<template>
    <div class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15" style="background: linear-gradient(rgba(29, 131, 211, 0.38) 0px, rgba(29, 131, 211, 0));">
                <div class="container">
                    <div class="my-7">
                        <h4 class="text-center w-100">
                            <i class="fa-regular fa-robot titleIcon mr-2"></i>{{ $t('message.support') }}
                        </h4>
                        <p class="d-inline-block w-100 text-center" style="font-size: 24px;">{{ $t('message.how_can_we_help') }}</p>
                    </div>
                </div>
            </section>
            <section class="sectionGauche mt-9 pb-15">
                <div class="container">
                    <div id="assist" class="row">
                        <div class="col-12 col-md-4">
                            <div class="assistanceCube" @click="navigateToRefresh('API')">
                                <i class="fas fa-code" aria-hidden="true"></i>
                                <h5>{{ $t('message.api_documentation') }}</h5>
                                <p>{{ $t('message.use_api_configure_services') }}</p>
                                <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
<!--                            <div @click="navigateToRefresh('tuto')" class="assistanceCube"> -->
                            <a style="color: unset;text-decoration: unset " target="_blank" href="https://www.youtube.com/@beTool-jv2mk" >
                                <div  class="assistanceCube">
                                    <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                                    <h5>{{ $t('message.video_tutorials') }}</h5>
                                    <p>{{ $t('message.train_become_autonomous_platform') }}</p>
                                    <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                                </div>
                            </a>

                        </div>
                        <div class="col-12 col-md-4">
                            <div class="assistanceCube" @click="modalOpen = true">
                                <i class="fad fa-ticket" aria-hidden="true"></i>
                                <h5>{{ $t('message.support_ticket') }}</h5>
                                <p>{{ $t('message.report_track_incident_consult_history') }}</p>
                                <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="container">
                <frequently-asked/>
            </section>
            <support-stage/>
           <section-pub/>
        </div>
        <modal-support :modal-support.sync="modalOpen"/>
    </div>
</template>

<script>
import SectionPub from "@/components/micro/sectionPub.vue";
import SupportStage from "@/components/micro/supportStage.vue";
import ModalSupport from "@/components/modal/modalSupport.vue";
import FrequentlyAsked from "@/components/micro/frequentlyAsked.vue";

export default {
    name: "supportApp",
    components: {FrequentlyAsked, ModalSupport, SupportStage, SectionPub},
    data: () => ({
        valid: true,
        modalOpen: false,
    }),

    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },

    },
}
</script>

<style scoped>

</style>