<template>
    <div id="servicePage" class="pageStructure">
        <div class="allSection">

            <section class="sectionGauche pt-9 pb-15" style="background: linear-gradient(rgb(204,241,255) 0px, rgba(211, 169, 29, 0));">
                <div class="container">
                    <div class="my-7"><h4 class="text-center w-100"><i class="far fa-tools mr-2" aria-hidden="true"></i> {{ $t('message.servicess') }}</h4></div>
                </div>
            </section>
            <div class="container">
                <div class="row">
                    <div class="sectionOne col-12 py-0">
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Document.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.documentsTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.documentsDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.documentsDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Tchat.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.smsTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.smsDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.smsDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Calendrier.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.calendarTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.calendarDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.calendarDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Comptabilite.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.accountingTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.accountingDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.accountingDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Formulaire.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.formTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.formDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.formDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Produit.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">{{ $t('message.productsTitle') }}</div>
                                    <p class="txtHeaderP">{{ $t('message.productsDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.productsDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover" style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Email.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">Email</div>
                                    <p class="txtHeaderP">{{ $t('message.emailDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.emailDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover"
                                             style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Lead.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">Piste / leads</div>
                                    <p class="txtHeaderP">{{ $t('message.leadsDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.leadsDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover"
                                             style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Esign.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">E-sign</div>
                                    <p class="txtHeaderP">{{ $t('message.esignDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.esignDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover"
                                             style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Map.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">MAP</div>
                                    <p class="txtHeaderP">{{ $t('message.mapDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.mapDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover"
                                             style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/Ipphone.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">Téléphonie IP</div>
                                    <p class="txtHeaderP">{{ $t('message.telephonyDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.telephonyDescriptionPlus')"></div>
                        </div>
                        <div class="objectLinkR ik">
                            <div class="ajustLine d-flex">
                                <div class="imgMenuApp">
                                    <div class="v-image v-responsive imgHeaderApp theme--light">
                                        <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                        <div class="v-image__image v-image__image--cover"
                                             style="background-image: url(https://objectif-crm.com/assets/images/iconCrm/contactComp.png); background-position: center center;"></div>
                                        <div class="v-responsive__content" style="width: 1024px;"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="contentTitle">Contact et compagnie</div>
                                    <p class="txtHeaderP">{{ $t('message.contactCompanyDescription') }}</p>
                                </div>
                            </div>
                            <div v-html="$t('message.contactCompanyDescriptionPlus')"></div>
                        </div>
                    </div>

                </div>
                <pub-bleu/>
            </div>
        </div>
    </div>
</template>

<script>
import PubBleu from "@/components/micro/pubBleu.vue";

export default {
    name: "servicesApp",
    components: {PubBleu}
}
</script>

<style scoped>

</style>