<template>
    <div id="pricing" class="pageStructure">
        <div class="allSection">
            <div class="container">
                <div class="mt-9 v-card v-card--flat v-sheet theme--light">
                    <div class="v-window v-item-group theme--light">
                        <div class="v-window__container">
                            <div class="v-window-item v-window-item--active">
                                <div class="container container--fluid">
                                    <section>
                                        <h2 class="text-center mt-8">{{ $t('message.EnsembleProductivite') }}<br><span class="gratuitt">{{ $t('message.CommencezGratuitement') }}</span>.</h2>
                                        <p class="text-center m-0">{{ $t('message.PeriodeEssai') }}</p>
                                        <div class="w-100 d-flex mt-7 justify-content-center align-items-center">
                                            <div class="v-item-group theme--dark v-btn-toggle v-btn-toggle--dense primary">
                                                <button @click="pricingType = 'annual'"
                                                        class="v-btn v-item--active v-btn--active v-btn--is-elevated v-btn--has-bg theme--dark v-size--default">
                                                    <span class="v-btn__content">{{ $t('message.annual') }}</span>
                                                </button>
                                                <button @click="pricingType = 'monthly'" type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default">
                                                    <span class="v-btn__content">{{ $t('message.monthly') }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <form>
                                                <div class="per-custom mt-10">
                                                    <div class="d-flex allProduct pt-10" style="overflow-x: auto;">

                                                        <div class="productOne position-relative">
                                                            <div class="card mb-5 mb-lg-0 h-100">
                                                                <div class="background-price-1" style="background-color: rgb(228, 3, 165);"></div>
                                                                <div class="price-card-title text-center">
                                                                    <h4 class="color-4 fw-400 py-2 m-0" style="margin-top: 20px; color: rgb(228, 3, 165); font-size: 34px;">{{ $t('message.solo') }}</h4>
                                                                    <p class="font-1 color-6 mb-5"><span>{{ $t('message.price') }}</span> {{ $t('message.freeLifetime') }}</p>
                                                                    <div class="contentPrice text-left">
                                                                        <p class="ml-2">{{ $t('message.individualPlanIncludes') }}</p>
                                                                        <ul>
                                                                            <li>{{ $t('message.collaborativeWorkspace') }}</li>
                                                                            <li>{{ $t('message.freeAPIIntegration') }}</li>
                                                                            <li>{{ $t('message.limitedTimeHistory') }}</li>
                                                                            <li>{{ $t('message.powerfulFormulas') }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer text-center w-100">
                                                                    <button type="button" @click="modalOpen=true" class="v-btn v-btn--has-bg theme--light v-size--default" style="background-color: rgb(228, 3, 165); border-color: rgb(228, 3, 165); color: white;">
                                                                        <span  class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="productOne position-relative">
                                                            <div class="card mb-5 mb-lg-0 h-100">
                                                                <div class="background-price-1" style="background-color: rgb(82, 113, 255);"></div>
                                                                <div class="price-card-title text-center">
                                                                    <h4 class="color-4 fw-400 py-2 m-0" style="margin-top: 20px; color: rgb(82, 113, 255); font-size: 34px;">{{ $t('message.basic') }}</h4>
                                                                    <p class="font-1 color-6 mb-5"><span>{{ getPriceById(1) }}€</span> {{ $t('message.perUserMonth') }}</p>
                                                                    <div class="contentPrice text-left">
                                                                        <p class="ml-2">{{ $t('message.includesIndividualPlus') }}</p>
                                                                        <ul>
                                                                            <li>{{ $t('message.priorityCustomerService') }}</li>
                                                                            <li>{{ $t('message.fileStorage') }}</li>
                                                                            <li>{{ $t('message.limitedTimeHistory30') }}</li>
                                                                            <li>{{ $t('message.unlimitedItems') }}</li>
                                                                            <li>{{ $t('message.basicPermissionManagement') }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer text-center w-100">
                                                                    <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default" style="background-color: rgb(82, 113, 255); border-color: rgb(82, 113, 255); color: white;">
                                                                        <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="productOne position-relative bestProduct">
                                                            <div class="chipProduuct">
        <span class="v-chip v-chip--no-color theme--light v-size--default">
            <span class="v-chip__content">{{ $t('message.recommended') }}</span>
        </span>
                                                            </div>
                                                            <div class="card mb-5 mb-lg-0 h-100" style="background-color: rgb(10, 61, 98);">
                                                                <div class="price-card-title text-center">
                                                                    <h4 class="color-4 fw-400 py-2 m-0" style="margin-top: 20px; color: rgb(255, 255, 255); font-size: 34px;">
                                                                        {{ $t('message.standard') }}
                                                                    </h4>
                                                                    <p class="font-1 color-6 mb-5" style="color: white;">
                                                                        <span>{{ getPriceById(2) }}€</span> {{ $t('message.perUserMonth') }}
                                                                    </p>
                                                                    <div class="contentPrice text-left" style="color: white;">
                                                                        <p class="ml-2">{{ $t('message.includesBasicPlus') }}</p>
                                                                        <ul>
                                                                            <li>{{ $t('message.calendarView') }}</li>
                                                                            <li>{{ $t('message.unlimitedAutomations') }}</li>
                                                                            <li>{{ $t('message.limitedTimeHistory7') }}</li>
                                                                            <li>{{ $t('message.VIPServiceManagement') }}</li>
                                                                            <li>{{ $t('message.permissionManagementAllLevels') }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer text-center w-100">
                                                                    <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default" style="background-color: rgb(255, 255, 255); border-color: rgb(255, 255, 255); color: black;">
                                                                        <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="productOne position-relative">
                                                            <div class="card mb-5 mb-lg-0 h-100">
                                                                <div class="background-price-1" style="background-color: rgb(0, 194, 203);"></div>
                                                                <div class="price-card-title text-center">
                                                                    <h4 class="color-4 fw-400 py-2 m-0" style="margin-top: 20px; color: rgb(0, 194, 203); font-size: 34px;">
                                                                        {{ $t('message.pro') }}
                                                                    </h4>
                                                                    <p class="font-1 color-6 mb-5">
                                                                        <span>{{ getPriceById(3) }}€</span> {{ $t('message.perUserMonth') }}
                                                                    </p>
                                                                    <div class="contentPrice text-left">
                                                                        <p class="ml-2">{{ $t('message.includesStandardPlus') }}</p>
                                                                        <ul>
                                                                            <li>{{ $t('message.documentVisualizationSAS') }}</li>
                                                                            <li>{{ $t('message.fileStorage15') }}</li>
                                                                            <li>{{ $t('message.premiumSupport') }}</li>
                                                                            <li>{{ $t('message.createDashboard') }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer text-center w-100">
                                                                    <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default" style="background-color: rgb(0, 194, 203); border-color: rgb(0, 194, 203); color: white;">
                                                                        <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="productOne position-relative">
                                                            <div class="card mb-5 mb-lg-0 h-100">
                                                                <div class="background-price-1" style="background-color: black;"></div>
                                                                <div class="price-card-title text-center">
                                                                    <h4 class="color-4 fw-400 py-2 m-0" style="margin-top: 20px; color: rgb(0, 0, 0); font-size: 34px;">
                                                                        {{ $t('message.enterprise') }}
                                                                    </h4>
                                                                    <p class="font-1 color-6 mb-5"><span style="height: 53px;"></span> {{ $t('message.custom') }}</p>
                                                                    <div class="contentPrice text-left">
                                                                        <p class="ml-2">{{ $t('message.includesProPlus') }}</p>
                                                                        <ul>
                                                                            <li>{{ $t('message.unlimitedPlugins') }}</li>
                                                                            <li>{{ $t('message.unlimitedFileStorage') }}</li>
                                                                            <li>{{ $t('message.unlimitedSetupIntegration') }}</li>
                                                                            <li>{{ $t('message.unlimitedTimeHistory') }}</li>
                                                                            <li>{{ $t('message.powerfulFormulas') }}</li>
                                                                            <li>{{ $t('message.onDemandDevelopment') }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer text-center w-100">
                                                                    <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default black" style="color: white;">
                                                                        <span class="v-btn__content">{{ $t('message.contactUs') }}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                            <div class="my-6 mt-16 text-center">
                                                <h5>{{ $t('message.note') }}</h5>
                                                <i>
                                                    {{ $t('message.someAppsNote') }}
                                                    <a @click="navigateToRefresh('integrations')">{{ $t('message.here') }}</a>
                                                </i>
                                            </div>

                                        </div>
                                    </section>
                                </div>
                            </div>
                            <!---->
                        </div>
                    </div>
                </div>
                <table-compare/>

                <frequently-asked/>

            </div>
            <section-pub/>
        </div>
        <modal-lead :modal-lead.sync="modalOpen"/>
    </div>
</template>

<script>
import ModalLead from "@/components/modal/modalLead.vue";
import SectionPub from "@/components/micro/sectionPub.vue";
import TableCompare from "@/components/micro/tableCompare.vue";
import FrequentlyAsked from "@/components/micro/frequentlyAsked.vue";

export default {
    name: "pricingApp",
    components: {FrequentlyAsked, TableCompare, SectionPub, ModalLead},

    data() {
        return {
            valid: true,
            modalOpen: false,
            products: [
                {id: 1, name: 'Basic', annualPrice: 8, monthlyPrice: 10},
                {id: 2, name: 'Standard', annualPrice: 10, monthlyPrice: 12},
                {id: 3, name: 'Pro', annualPrice: 16, monthlyPrice: 20},
            ],
            pricingType: 'annual',
        };
    },

    methods: {
        getPriceById(id) {
            console.log(JSON.stringify(this.displayedProducts))
            return typeof this.displayedProducts.find(p => p.id === id) === "undefined" ? 0 : this.displayedProducts.find(p => p.id === id).price;
        },
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                window.location = routeName;
            }
        },

    },
    computed: {
        displayedProducts() {
            return this.products.map(product => {
                return {
                    ...product,
                    price: this.pricingType === 'monthly' ? product.monthlyPrice : product.annualPrice,
                }
            });
        },
    },
}
</script>

<style scoped>

</style>