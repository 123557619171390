<template>
    <section class="sectionInfoPlus mt-8 pb-15">
        <div class="mr-2 w-100">
            <div class="row">
                <div class="col-12 col-sm-6 justify-content-center">
                    <div class="v-card v-card--flat v-sheet theme--light">
                        <h4 class="infoSpecial VCT pl-6">{{ $t('message.wyBetool') }}</h4>
                        <div class="v-card__text infoSpecial VCTX">
                            <div class="d-flex mb-5">
                                <div class="iconSectionInfoPlus"><i class="fa-solid fa-code"></i></div>
                                <div class="crdP">
                                    <p>
                                        <strong>{{ $t('message.point1Title') }}</strong><br>
                                        {{ $t('message.point1Text') }}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex mb-5">
                                <div class="iconSectionInfoPlus"><i class="fa-solid fa-shield"></i></div>
                                <div class="crdP">
                                    <p>
                                        <strong>{{ $t('message.point2Title') }}</strong><br>
                                        {{ $t('message.point2Text') }}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex mb-5">
                                <div class="iconSectionInfoPlus"><i class="fa-regular fa-browser"></i></div>
                                <div class="crdP">
                                    <p>
                                        <strong>{{ $t('message.point3Title') }}</strong><br>
                                        {{ $t('message.point3Text') }}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex mb-5">
                                <div class="iconSectionInfoPlus"><i class="fa-duotone fa-share-nodes"></i></div>
                                <div class="crdP">
                                    <p>
                                        <strong>{{ $t('message.point4Title') }}</strong><br>
                                        {{ $t('message.point4Text') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-btn text light href="https://application.betool.fr/register?acces=freetrial">
                        {{ $t('message.buttonText') }}
                        <v-icon right>fas fa-arrow-right</v-icon>
                    </v-btn>

                </div>
                <div class="d-none d-sm-inline-block col-sm-6">
                    <div class="v-card v-card--flat v-sheet theme--light">
                        <div class="v-image v-responsive theme--light" style="height: 379px;">
                            <div class="v-responsive__sizer" style="padding-bottom: 83.9062%;"></div>
                            <div class="v-image__image v-image__image--cover" style="background-image: url(https://i.ibb.co/mDKNztP/imgPK.png); background-position: center center;"></div>
                            <div class="v-responsive__content" style="width: 640px;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "whyChoose"
}
</script>

<style scoped>

</style>