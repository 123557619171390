<template>
    <div id="nocode" class="pageStructure" style="position: relative;">
        <img src="https://www.objectif-crm.com/assets/images/bckAll.png" class="imageBckFondu" style="top: 0;">
        <div class="allSection">
            <div class="container">
                <section class="slideUp mt-9">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-6 h-100">
                            <div class="pr-15 pl-10 pl-md-0">
                                <h1>{{ $t('message.nocode_title') }}</h1>
                                <p>{{ $t('message.nocode_description') }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="imgNocodeSlideP w-100 px-16">
                                <img src="https://www.objectif-crm.com/assets/images/Nocode.png" class="imgNocodeSlide w-100 p-10">
                            </div>
                        </div>
                    </div>
                </section>
                <section class="px-5 px-md-0">
                    <h5 style="font-size: 28px; margin-bottom: 15px;">{{ $t('message.intro_title') }}</h5>
                    <p>{{ $t('message.intro_description1') }}</p>
                    <p>{{ $t('message.intro_description2') }}</p>
                </section>
                <section class="position-relative">
                    <img src="https://www.objectif-crm.com/assets/images/backFondu.png" class="imageBckFondu">
                    <div class="cont-sel" style="position: relative;">
                        <div class="indexStiky d-none d-md-block">
                            <div class="indexNocode">
                                <div class="indexCadre">
                                    <h5 class="mb-5" style="text-decoration: underline;">{{ $t('message.advantages_title') }}</h5>
                                    <a href="#limite" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.limit_skills') }}</span>
                                    </a>
                                    <a href="#gain" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.time_saving') }}</span>
                                    </a>
                                    <a href="#cout" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.cost_reduction2') }}</span>
                                    </a>
                                    <a href="#flexi" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.flexibility') }}</span>
                                    </a>
                                    <a href="#evol" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.scalability') }}</span>
                                    </a>
                                    <a href="#integr" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.integrations') }}</span>
                                    </a>
                                    <a href="#autom" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.automation') }}</span>
                                    </a>
                                    <a href="#rapid" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.market_speed') }}</span>
                                    </a>
                                    <a href="#mainte" class="btnIndex v-btn v-btn--text theme--light v-size--default">
                                        <span class="v-btn__content">{{ $t('message.no_maintenance') }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="descriptifs">
                            <div class="w-100 px-5 contentNocode">
                                <h4 class="mb-8">{{ $t('message.reasons_title') }}</h4>
                                <h5 id="limite">{{ $t('message.limit_skills') }}</h5>
                                <p>{{ $t('message.limit_skills_description') }}</p>
                                <h5 id="gain">{{ $t('message.time_saving') }}</h5>
                                <ul>
                                    <li>{{ $t('message.time_saving_point1') }}</li>
                                    <li>{{ $t('message.time_saving_point2') }}</li>
                                    <li>{{ $t('message.time_saving_point3') }}</li>
                                </ul>

                                <h5 id="cout">{{ $t('message.cost_reduction') }}</h5>
                                <ul>
                                    <li>{{ $t('message.cost_reduction_point1') }}</li>
                                    <li>{{ $t('message.cost_reduction_point2') }}</li>
                                </ul>

                                <h5 id="flexi">{{ $t('message.flexibility') }}</h5>
                                <ul>
                                    <li>{{ $t('message.flexibility_point1') }}</li>
                                    <li>{{ $t('message.flexibility_point2') }}</li>
                                    <li>{{ $t('message.flexibility_point3') }}</li>
                                </ul>

                                <h5 id="evol">{{ $t('message.scalability') }}</h5>
                                <ul>
                                    <li>{{ $t('message.scalability_point1') }}</li>
                                    <li>{{ $t('message.scalability_point2') }}</li>
                                </ul>

                                <h5 id="integr">{{ $t('message.integrations') }}</h5>
                                <p>{{ $t('message.integrations_description') }}</p>

                                <h5 id="autom">{{ $t('message.automation') }}</h5>
                                <p>{{ $t('message.automation_description') }}</p>

                                <h5 id="rapid">{{ $t('message.market_speed') }}</h5>
                                <ul>
                                    <li>{{ $t('message.market_speed_point1') }}</li>
                                    <li>{{ $t('message.market_speed_point2') }}</li>
                                    <li>{{ $t('message.market_speed_point3') }}</li>
                                </ul>

                                <h5 id="mainte">{{ $t('message.no_maintenance') }}</h5>
                                <p>{{ $t('message.no_maintenance_description') }}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="supportCube d-flex md-flex-column" style="border-bottom: none;">
                        <div class="imgContentAU " style="width: unset;">
                            <img src="https://www.objectif-crm.com/assets/images/Publiques.png" class="imgAboutUs" style="width: unset; border-radius: 20px;">
                        </div>
                        <div>
                            <h6 class="m-0 p-0 my-4">{{ $t('message.target_audience_title') }}</h6>
                            <p>{{ $t('message.target_audience_description') }}</p>
                        </div>
                    </div>
                </section>
            </div>
            <section class="sectionPub pb-15">
                <div class="mr-2 w-100">
                    <div class="text-center actionSection v-card v-card--flat v-sheet theme--light">
                        <h4>{{ $t('message.custom_work_software_title') }}</h4>
                        <div class="py-3">
                            <p class="mb-0 descripH1" style="max-width: 100%;">{{ $t('message.free_trial_description') }}</p>
                        </div>
                        <div class="v-card__actions infoSpecial VCA justify-content-center">
                            <button type="button" class="v-btn v-btn--outlined theme--light v-size--default">
                                <span class="v-btn__content">{{ $t('message.get_started') }} <i class="ml-2 fa-regular fa-right-long"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "noCode"
}
</script>

<style scoped>

</style>