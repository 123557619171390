<template>
    <section class="sectionTrio mt-8 pb-15">
        <h4 class="text-center">{{ $t('message.sectionTitle') }}</h4>
        <div class="row mt-10">
            <div class="col-12 col-md-4">
                <div class="v-card v-card--flat v-sheet theme--light">
                    <div class="crdIcon"><i class="fa-solid fa-users-gear"></i></div>
                    <div class="v-card__title">{{ $t('message.card1Title') }}</div>
                    <div class="v-card__text">{{ $t('message.card1Text') }}</div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="v-card v-card--flat v-sheet theme--light">
                    <div class="crdIcon" style="background-color: rgb(62, 103, 135);"><i class="fa-regular fa-square-kanban"></i></div>
                    <div class="v-card__title">{{ $t('message.card2Title') }}</div>
                    <div class="v-card__text">{{ $t('message.card2Text') }}</div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="v-card v-card--flat v-sheet theme--light">
                    <div class="crdIcon" style="background-color: rgb(255, 99, 94);"><i class="fa-regular fa-database"></i></div>
                    <div class="v-card__title">{{ $t('message.card3Title') }}</div>
                    <div class="v-card__text">
                        {{ $t('message.card3Text') }}
                        <div class="d-flex">
                            <div><a class="ml-1" @click="navigateToRefresh('services')">{{ $t('message.card3Link1') }}</a></div>
                            <v-spacer/>
                            <div><a class="ml-1" @click="navigateToRefresh('integrations')">{{ $t('message.card3Link2') }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "allInOne",

    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },
    },
}
</script>

<style scoped>

</style>