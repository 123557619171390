
<template>
  <div id="betoolTrust" class="pageStructure">
    <div class="allSection">
          <section class="sectionGauche pt-9 pb-15" style="background: linear-gradient(#f3d3e4 0px, rgba(29, 131, 211, 0));">
            <div class="container">
              <div class="my-7"><h4 class="text-center w-100"><i class="fa-duotone fa-camera-retro titleIcon mr-2"></i> Horodatage et géolocalisation de photos</h4></div>
            </div>
          </section>
          <div class="container">
            <section style="padding: 95px 0">
              <div class="d-flex">
<!--                <div class="p-3 d-none d-md-inline-block">-->
<!--                  <img src="https://objectif-crm.com/assets/images/perfsign.png" style="max-width: 210px; width: 210px; height: 210px;">-->
<!--                </div>-->
                <div class="p-3 w-100">
                  <h3><i class="fa-solid fa-shield-quartered"></i> La preuve numérique fiable</h3>
                  <p class="content-medium">
                    Conçu pour répondre aux exigences rigoureuses des enquêtes légales et des évaluations professionnelles, notre service fournit une solution infaillible pour authentifier des scènes ou des événements avec précision. Grâce à notre technologie avancée, chaque photo capturée est non seulement ancrée dans le temps par un horodatage précis, mais elle est également géolocalisée, offrant ainsi une localisation exacte de l'instant capturé.
                    Nous garantissons l'intégrité de chaque enregistrement par l'intermédiaire d'une signature électronique robuste, émanant d'un tiers de confiance. De plus, pour assurer la pérennité et la sécurité des données, chaque photo est stockée dans un espace cloud hautement sécurisé. Cette approche méthodique assure à nos utilisateurs une preuve numérique de valeur juridique indéniable, conforme aux normes les plus strictes en matière de preuve numérique au sein de l'Union Européenne.
                  </p>
                  <br><br>
                  <p class="content-medium">Que vous souhaitiez documenter un état des lieux, signaler un incident ou simplement consigner des événements significatifs avec une précision spatiale et temporelle vérifiable, notre service est la garantie que vos images serviront de témoignages fiables et indiscutables.</p>

                </div>
              </div>
            </section>

            <!--                          a traduire-->
            <section class="sectionInfoPlus m-0" style="padding: 95px 0 ;background-color: #f5e4ee;">
              <div class="w-100">
                <div class="row">
                  <div class="col-12 col-sm-8 justify-content-center">
                    <div class="v-card v-card--flat v-sheet theme--light">
<!--                      <h4 class="infoSpecial VCT pl-6">{{ $t('message.wyBetool') }}</h4>-->
                      <div class="v-card__text infoSpecial VCTX" style="padding: 40px;">
                        <div class="d-flex mb-5">
                          <div class="iconSectionInfoPlu"><i class="fa-duotone fa-camera-retro"></i></div>
                          <div class="crdP">
                            <p>
                              <strong style="font-size: 22px;">Prennez une photo via notre application</strong><br>
                              Prenez une photo en toute simplicité comme sur n'importe quel smartphone. Vous pouvez également y adjoindre un commentaire si vous le souhaitez.
                            </p>
                          </div>
                        </div>
                        <div class="d-flex mb-5">
                          <div class="iconSectionInfoPlu"><i class="fa-solid fa-shield"></i></div>
                          <div class="crdP">
                            <p>
                              <strong style="font-size: 22px;">Localisation</strong><br>
                              L'application vous géolocalise en quelques secondes par GPS. Si aucun signal n'est disponible, vous pouvez certifier votre photo sans la géolocaliser.
                            </p>
                          </div>
                        </div>
                        <div class="d-flex mb-5">
                          <div class="iconSectionInfoPlu"><i class="fa-regular fa-browser"></i></div>
                          <div class="crdP">
                            <p>
                              <strong style="font-size: 22px;">Certification et scelee</strong><br>
                              Confirmez vos choix, validez et... c'est fini ! Le certificat vous est delivrer et le sceler est pose.
                            </p>
                          </div>
                        </div>
                        <div class="d-flex mb-5">
                          <div class="iconSectionInfoPlu"><i class="fa-duotone fa-share-nodes"></i></div>
                          <div class="crdP">
                            <p>
                              <strong style="font-size: 22px;">Horodater</strong><br>
                              Confirmez vos choix, validez et... c'est fini ! Le certificat vous est delivrer et le sceler est pose.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-none d-sm-inline-block col-sm-3">
                    <v-card flat>
                      <v-img src="https://betool.fr/images/photoSite.png"/>


<!--                      <div class="v-image v-responsive theme&#45;&#45;light" style="height: 379px;">-->
<!--                        <div class="v-responsive__sizer" style="padding-bottom: 83.9062%;"></div>-->
<!--                        <div class="v-image__image v-image__image&#45;&#45;cover" style="background-image: url(https://betool.fr/images/photoSite.png); background-position: center center;"></div>-->
<!--                        <div class="v-responsive__content" style="width: 640px;"></div>-->
<!--                      </div>-->
                    </v-card>
                  </div>
                </div>
              </div>
            </section>

            <section class="cetree" style="padding: 95px 0">
              <div class="d-flex">
                <div class="p-3 px-md-11 w-100">
                  <h3 class="text-center w-100">1. Autonomie et intégrité des certificats</h3>
                  <p class="content-medium text-center">
                    Nos certificats sont conçus pour être entièrement auto-contenus, intégrant de manière autonome toutes les données cryptographiques et éléments de mise en forme essentiels. Cette indépendance garantit leur intégrité et leur fiabilité à travers le temps. Contrairement aux solutions dépendantes de systèmes externes, tels que la blockchain, qui peuvent être sujets à corruption ou à disparition, nos certificats se distinguent par leur robustesse et leur pérennité.
                  </p>
                </div>
              </div>
                <div class="d-flex">
                <div class="p-3 px-md-11 w-100">
                  <h3 class="text-center w-100">2. Sécurité renforcée et fiabilité à long terme</h3>
                  <p class="content-medium text-center">
                    En éliminant la dépendance à des entités ou infrastructures externes, nous offrons une sécurité inégalée. Nos certificats bénéficient d'une protection contre les vulnérabilités externes, assurant ainsi une fiabilité continue. Cette approche indépendante permet une vérification simple et fiable de l'authenticité et de l'intégrité des informations, quel que soit le moment où elles sont consultées.                  </p>
                </div>
                </div>
                  <div class="d-flex">
                <div class="p-3 px-md-11 w-100">
                  <h3 class="text-center w-100">3. Engagement envers la durabilité des preuves numériques</h3>
                  <p class="content-medium text-center">
                    Notre engagement est de fournir une solution de preuve numérique qui résiste à l'épreuve du temps. En garantissant que nos certificats maintiennent leur intégrité et disponibilité sans limite de durée, nous offrons à nos utilisateurs la tranquillité d'esprit. Les documents restent sécurisés, accessibles, et leur véracité est incontestable, répondant ainsi aux exigences les plus strictes en matière d'authentification et de conservation des données numériques.                  </p>
                </div>
              </div>
            </section>

<!--            <section class="sectionCRM mt-15 pb-cus-150">-->
<!--              <div class="row" style="background-color: transparent !important;">-->
<!--                <div class="col-12 col-sm-6 d-flex align-items-center">-->
<!--                  <div class="d-flex align-items-center justify-content-center w-100 h-100">-->
<!--                    <img src="https://objectif-crm.com/assets/images/signPaper.jpg" class="imageTextRight" style="max-width: 459px;">-->
<!--                  </div>-->
<!--                </div>-->


<!--                <div class="col-12 col-sm-6 h-100" style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">-->
<!--                  <div class="d-flex justify-content-center align-items-center" style="background-color: transparent !important;">-->
<!--                    <div class="mt-5 v-card v-card&#45;&#45;flat v-sheet theme&#45;&#45;light" style="background-color: transparent !important;">-->
<!--                      <div class="v-card__text vCardTxt">{{ $t('message.theFuture') }}</div>-->
<!--                      <h4>{{ $t('message.electronicSignature') }}</h4>-->
<!--                      <p class="descripH1 mb-0">{{ $t('message.electronicSignatureDescription1') }}</p>-->
<!--                      <p class="descripH1 mb-0">{{ $t('message.electronicSignatureDescription2') }}</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </section>-->


<!--            <section class="sectionCRM pb-cus-150" style="padding: 95px 0">-->
<!--              <div class="row" style="background-color: transparent !important;">-->
<!--                <div class="col-12 col-sm-6 h-100" style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">-->
<!--                  <div class="d-flex justify-content-center align-items-center" style="background-color: transparent !important;">-->
<!--                    <div class="mt-5 v-card v-card&#45;&#45;flat v-sheet theme&#45;&#45;light" style="background-color: transparent !important;">-->
<!--                      <div class="v-card__text vCardTxt">{{ $t('message.Avantages') }}</div>-->
<!--                      <h4>{{ $t('message.LesAvantagesSignatureElectronique') }}</h4>-->
<!--                      <p class="descripH1 mb-0">{{ $t('message.IntegrerSignatureElectroniqueDescription1') }}</p>-->
<!--                      <ul class="mt-4">-->
<!--                        <li>{{ $t('message.AvantageReductionDelais') }}</li>-->
<!--                        <li>{{ $t('message.AvantageAugmentationSecurite') }}</li>-->
<!--                        <li>{{ $t('message.AvantageFacilitationGestion') }}</li>-->
<!--                        <li>{{ $t('message.AvantageAmeliorationExperience') }}</li>-->
<!--                        <li>{{ $t('message.AvantageOptimisationProcessus') }}</li>-->
<!--                      </ul>-->
<!--                      <p class="descripH1 mb-0">{{ $t('message.IntegrerSignatureElectroniqueDescription2') }}</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-12 col-sm-6 d-flex align-items-center">-->
<!--                  <div class="d-flex align-items-center justify-content-center w-100 h-100">-->
<!--                    <img src="https://objectif-crm.com/assets/images/signtop.gif" class="imageTextRight" style="max-width: 459px;">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </section>-->
          </div>


          <section class="my-16" style="background-color: rgb(255, 239, 253);padding: 95px 0">
            <div class="container">
              <div class="justify-content-center align-items-center">
                <div class="d-flex align-items-center justify-content-center w-100 h-100">
                  <img src="https://objectif-crm.com/assets/images/signtop.png" class="imageTextRight" style="max-width: 459px;">
                </div>
              </div>
              <h4 class="text-center">{{ $t('message.ContactezNousEnSavoirPlus') }}</h4>
              <p class="descripH1 mb-0 w-100 text-center" style="max-width: unset;">{{ $t('message.ContactezNousDescription') }}</p>
              <div class="v-card__actions mt-11 pb-8 justify-content-center align-items-center">
                <button type="button" class="v-btn v-btn--outlined theme--light v-size--default" style="color: black;"><span class="v-btn__content">{{ $t('message.DemandeRappel') }}</span></button>
              </div>
            </div>
          </section>
        </div>
  </div>
</template>
<script>
export default {
  name: 'betoolTrust',
  components: {
  },

  data: () => ({
    //
  }),
};
</script>

