<template>
    <div class="pageStructure">
        <div class="allSection">
            <support-stage/>
            <section id="support" class="bb-section mt-8" style="background-color: rgba(239, 220, 255, 0.25);">
                <div class="container">
                    <div class="produitAll">
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line" style="display: block; text-align: left; position: relative;">
                                <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">{{ $t('message.Support') }}</div>
                            </div>
                        </h1>
                        <div class="row mt-9">
                            <div class="col-12">
                                <div class="supportCube d-flex flexMobilVertical" id="standart">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/Simple.png" class="imgAboutUs" style="padding: 10px;"></div>
                                    <div ><h6 class="m-0 p-0 my-4">{{ $t('message.SupportStandard') }}</h6>
                                        <p>{{ $t('message.SupportStandardDescription') }}</p></div>
                                </div>
                                <div class="supportCube d-flex flexMobilVertical" id="premium">
                                    <div class="imgContentAU">
                                        <img src="https://www.objectif-crm.com/assets/images/Proche.png" class="imgAboutUs">
                                    </div>
                                    <div ><h6 class="m-0 p-0 my-4">{{ $t('message.SupportPremium') }}</h6>
                                        <p>{{ $t('message.SupportPremiumDescription') }}</p></div>
                                </div>
                                <div class="supportCube d-flex flexMobilVertical" id="pro">
                                    <div class="imgContentAU">
                                        <img src="https://www.objectif-crm.com/assets/images/Pro.png" class="imgAboutUs"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.SupportBusiness') }}</h6>
                                        <p>{{ $t('message.SupportBusinessDescription') }}</p>
                                    </div>
                                </div>
                                <div class="supportCube d-flex flexMobilVertical" id="entreprise" style="border: none">
                                    <div class="imgContentAU">
                                        <img src="https://www.objectif-crm.com/assets/images/Buisness.png" class="imgAboutUs">
                                    </div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.SupportEnterprise') }}</h6>
                                        <p>{{ $t('message.SupportEnterpriseDescription') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="sectionPub px-2 px-md-0 mt-0 pb-15">
                <div class="mr-2 w-100">
                    <div class="text-center actionSection v-card v-card--flat v-sheet theme--light" style="background-color: #dcedff!important;">
                        <h4>{{ $t('message.SupportRequest') }}</h4>
                        <div class="v-card__actions infoSpecial VCA justify-content-center">
                            <v-btn outlined text @click="modalOpen = true">{{ $t('message.CreateTicket') }} <v-icon class="ml-2" small style="color: black;">fas fa-ticket</v-icon></v-btn>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <modal-support :modal-support.sync="modalOpen"/>
    </div>
</template>

<script>

import SupportStage from "@/components/micro/supportStage.vue";
import ModalSupport from "@/components/modal/modalSupport.vue";


export default {
    name: "supportType",
    components: {ModalSupport, SupportStage},
    data: () => ({
        activeElement: null,
        valid: true,
        modalOpen: false,

    }),

    methods: {
        scrollTo(id) {
            const newActiveElement = document.getElementById(id);
            // const y = newActiveElement.getBoundingClientRect().top + window.pageYOffset - 60;
            const y = newActiveElement.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2 + newActiveElement.clientHeight / 2;

            // Enlever la classe de l'élément actif, si il y en a un
            if (this.activeElement) {
                this.activeElement.classList.remove("choiceActif");
            }

            // Ajouter la classe au nouvel élément actif
            newActiveElement.classList.add("choiceActif");

            // Mettre à jour l'élément actif
            this.activeElement = newActiveElement;

            window.scrollTo({top: y, behavior: 'smooth'});
        },
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },

    },

}
</script>

<style scoped>

</style>