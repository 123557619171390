export default {
    message: {
        construct: 'Under Construction -> 30/06',
        sloganPH1: 'noCode work software that adapts to your business',
        applicativeServices: "Applicative services",
        function: 'Function',
        manageCommunicateAutomate: 'Manage, communicate, automate your procedures thanks to our services',
        betoolServices: 'The services of beTool are the functional execution channels that allow better management of work harmony and management.',
        concreteExamples: 'Concrete examples:',
        dynamicForm: 'Dynamic form',
        productCatalogue: 'Product catalogue',
        calendar: 'Calendar',
        accounting: 'Accounting',
        learnMore: 'Learn more',
        appsPlugins: 'Apps and plugins',
        connectivity: 'Connectivity',
        connectActivate: 'Connect and activate features through all kinds of apps and plugins.',
        appsProposed: 'The proposed applications facilitate the management of relationships with external applications or systems, thus promoting the centralization of data and features.',
        collaboratorsManagement: 'Collaborators management',
        optimization: 'Optimization',
        optimizeTeams: 'Optimize your teams and their production',
        managementRights: 'Collaborators management in terms of rights and permissions is crucial for optimal productivity and accurate roles management.',
        taskManagement: 'Task management',
        projectManagement: 'Project management',
        automatedWorkProcesses: 'Automated work processes',
        createYours: 'Create yours',
        dynamism: 'Dynamism',
        createDatabase: 'Create your databases thanks to a dynamic and noCode administration',
        dynamicCreationTool: 'With the dynamic creation tool, creating custom data models allows you to collaborate and edit documents with ease.',
        crm: 'CRM',
        erp: 'ERP',
        kanban: 'Kanban',
        ticketing: 'Ticketing',
        applicationServices: 'APPLICATIVE SERVICES',
        services: 'Services',
        allInOne: 'All in one',
        servicesDescription: 'Our services simplify data management by offering pre-designed complex structures, adapted to different business sectors. They help you create efficient databases and maximize the use of the tool thanks to tailored training. In short, our services are essential for managing, communicating, and automating your procedures.',
        appAndPlugins: 'APPS AND PLUGINS',
        integration: 'Integration',
        effectiveCommunication: 'Integrate effective communication methods',
        integrationDescription: 'Easily customize your CRM to work for you, without needing to call in a developer. Modify contract signing steps, add as many columns as you want, manage several pipelines at once, and much more.',
        moreThan100Plugins: 'Over 100 plugins',
        discoverIntegrations: 'Discover integrations and plugins',
        collaboratorManagement: 'Collaborator management',
        collaboration: 'Collaboration',
        collaborateToSucceed: 'Collaborate to succeed and innovate',
        collaborationDescription: 'A good collaboration allows centralizing all the information related to a project, such as tasks, deadlines, comments, files, messages, etc. This allows each team member to quickly and easily access all the information they need to complete their work. It also helps structure the team so that each member has a clear role and defined responsibilities. This optimizes collaboration and reduces processing errors',
        customizableToMatch: 'Customizable to match your sales cycle',
        customizationDescription: 'Thanks to our noCode platform, you can easily customize your processes and create custom automation scenarios without resorting to a developer. You can modify the steps, add as much data as you want, and manage multiple collaborators on multiple pipelines at the same time. All this allows you to be autonomous and use our platform without the need for a developer.',
        discoverProcessData: 'Discover process data',
        home: 'Home',
        applicationsPlugins: 'Applications and Plugins',
        features: 'Features',
        automations: 'Automations',
        integrations: 'Integrations',
        views: 'Views',
        electronicSignature: 'Electronic Signature',
        client: 'Client',
        login: 'Login',
        support: 'Support',
        contact: 'Contact',
        termsOfUse: 'Terms of Use',
        underConstruction: 'Site under construction / Planned for 06/30/23',
        connectActivateFeatures: 'Connect and activate features through all kinds of apps and plug-ins.',
        api: 'API',
        applications: 'Applications',
        synchronization: 'Synchronization',
        createDatabasesDynamicNOCODE: 'Create your databases thanks to a dynamic and noCode administration.',
        tickets: 'Tickets',
        tasks: 'Tasks',
        hello: 'Hello',
        tools: 'Tools',
        toolbox: 'Toolbox',
        appServices: 'Applicative services',
        email: 'Email',
        documents: 'Documents',
        sms: 'SMS',
        optimizeTeamsProduction: 'Optimize your teams and their production',
        roles: 'Roles',
        permissions: 'Permissions',
        letAutomationsWork: 'Let the automations work for you and focus on the essentials',
        simplifyWorkflow: 'Simplify your workflow thanks to integrations',
        maximizeProductivityEfficiency: 'Maximize your productivity and efficiency thanks to well-designed views',
        saveTimeEnvironment: 'Save time for you and for the environment, faster, easier and safer',
        pricing: 'Pricing',
        contactUs: 'Contact us',
        maximizeProductivity: 'Maximize your productivity and efficiency thanks to well-designed views.',
        company: 'Company',
        aboutUs: 'About us',
        whyBeTool: 'Why beTool?',
        whatIsNocode: 'What is noCode?',
        supportAide: 'Support',
        technicalSupport: 'Technical',
        salesSupport: 'Commercial',
        productTools: 'Product tools',
        legal: 'Legal',
        dmca: 'DMCA',
        CGU: 'Terms and conditions of use',
        privacyPolicy: 'Privacy policy',
        noCreditCard: 'No credit card required',
        tryFree: 'Try for free',
        allRightsReserved: 'All rights reserved.',
        // ic1

        description: 'Optimize the management of professional projects, such as collaborative management, lead and customer relationship management of your company, with a fun, 100% dynamic and noCode solution.',
        emailPlaceholder: 'Email',
        crdTitle1: 'Customize My Work Environment',
        crdTxt1: "A resourceful noCode solution.",

        crdAction1: 'What is noCode?',
        crdTitle2: 'Free Trial with No Commitment',
        crdTxt2: 'We offer you a 15-day trial.',
        crdAction2: 'Try Now',
        sectionTitle: 'All-in-One',
        card1Title: 'Manage Your Work Team',
        card1Text: 'Customize and enhance collaboration among your teams and gain complete visibility into production processes with advanced features tailored to your needs.',
        card2Title: 'Create Management Procedures',
        card2Text: 'Easily create management objects with functional and application widgets using drag & drop, while managing permissions for stakeholders.',
        card3Title: 'Services and Applications',
        card3Text: 'The intuitive interface offers services such as Email, SMS, Products, Calendar, and more.',
        card3Link1: 'Services?',
        card3Link2: 'Integrations?',
        wyBetool: 'Why Choose beTool?',
        point1Title: 'A NoCode Solution',
        point1Text: 'Easily customizable with drag & drop.',
        point2Title: '100% Self-Sufficient',
        point2Text: 'Simple and guided configuration.',
        point3Title: 'Over 30 Theme Templates',
        point3Text: 'Themes tailored to your profession/work.',
        point4Title: 'Sharing Option',
        point4Text: 'Easily share your work objects.',
        buttonText: 'Start Free Trial',
        integrationSectionTitle: 'Stay Connected to Your Favorite APIs with Applications and Plugins',
        integrationSectionText: 'Close deals and accelerate your projects without leaving your CRM or application. Improve synchronization, team efficiency, and productivity by connecting to integrated providers.',
        integrationSectionPlugins: 'Over 40 Plugins',
        integrationSectionButton: 'Discover beTool Integrations',
        sectionPubTitle: 'Your Tailor-Made Work Software',
        sectionPubText: 'Free 15-day trial - No credit card required.',
        sectionPubButton: 'Let\'s Go',
        sectionPricingTitle: 'Get More at the Best Price',
        sectionPricingTableHeader: 'This chart represents a partial list of features available in signNow, DocuSign, Adobe Sign, and HelloSign.',
        featureAccounting: 'Manage your business accounting',
        featureMarketing: 'Marketing',
        featureEmail: 'Email',
        featureSms: 'SMS',
        packagePricePerUser: 'Package Price per User',
        startFreeTrial: 'Start Free Trial',
        title: 'Support and Assistance',
        apis: 'APIs',
        apiDescription: 'API documentation is available for all import, export, or synchronization needs.',
        tutorials: 'Tutorials',
        tutorialsDescription: 'Learn all the tips and mastering methods of the tool through tutorials implemented throughout or in the Tutorial space.',
        assistance: 'Assistance',
        assistanceDescription: 'Our technical and sales support team is available to assist you in your endeavors.',
        beReady: 'BE READY TO HAVE MORE',
        getAccessNow: 'Get Your Access Now!',
        startTrial: 'Start Trial',
        requestCallback: 'Request a Callback',

// Automation page
        automation: 'Automation',
        advantages: 'Advantages',
        time_saving: 'Time Saving',
        time_saving_detail: 'Automation saves time by eliminating manual and repetitive tasks such as data collection and entry, sending reminders, or updating statuses. This allows team members to focus on higher-value tasks.',
        error_reduction: 'Error Reduction',
        error_reduction_detail: 'Automated tasks are less prone to human errors, reducing the risk of mistakes and improving the quality of results.',
        productivity_increase: 'Increase in Productivity',
        productivity_increase_detail: 'Automation frees up time for more important tasks, which can increase team productivity.',
        process_optimization: 'Process Optimization',
        process_optimization_detail: 'Automation can help optimize processes by identifying bottlenecks and inefficiencies. By optimizing processes, the team can reduce costs and improve the quality of results.',
        collaboration_improvement: 'Improvement in Collaboration',
        collaboration_improvement_detail: 'Automation can facilitate collaboration by eliminating manual tasks and allowing team members to focus on collaboration and communication.',
        cost_reduction: 'Cost Reduction',
        cost_reduction_detail: 'Automation can reduce costs by eliminating manual tasks and optimizing processes. It also helps reduce the risk of errors and delays, which can lower overall project costs.',
        email_templates: 'Sending Emails via Templates',
        create_records: 'Creating records in other processes',
        create_commissions: 'Creating commissions based on predefined rules',
        post_data: 'Making a controlled POST of data to an internal or external system',


        // about_us
        about_us: 'About Us',
        introduction_title: 'Introduction',
        introduction_text: "Our company is dedicated to providing entrepreneurs with a revolutionary business management tool. Our solution is fully customizable and requires no knowledge of computer programming - noCode. We are passionate about offering a smooth and enjoyable administration experience that allows entrepreneurs to manage all their processes from a single tool. We believe in the power of technology to help businesses thrive, and we take pride in offering a scalable and customizable solution with great data control.",
        history_title: 'History',
        history_text: "<strong>beTool</strong> was founded in 2018 with a modest goal: to create a small tool for a specific industry. Over time, thanks to our clients, we realized that the digital world was growing and needed modularity, efficiency, connectivity, and many other features to meet the needs of entrepreneurs. That's why in 2020, we started our project from scratch, creating an architecture capable of meeting most of our clients' expectations and providing them with everything they need. We are proud of our journey, and we will continue to listen to our clients and innovate to offer them the best business management tools.",
        team_title: 'Team',
        administration_title: 'Administration',
        developers_title: 'Developers',
        values: 'Values',
        innovationCreativity: 'Innovation and Creativity',
        innovationCreativityDescription: 'We are passionate about innovation and creativity, and we work every day to improve our products and services. We are constantly listening to our clients needs and always looking for new solutions to meet their demands.',
        transparencyIntegrity: 'Transparency and Integrity',
        transparencyIntegrityDescription: 'We are committed to maintaining transparent and honest relationships with our clients, partners, and employees. We take pride in our integrity and are always open to feedback and constructive criticism from our clients to improve ourselves.',
        excellence: 'Excellence',
        excellenceDescription: 'We strive for excellence in everything we do, from designing our products to delivering quality customer service. We are dedicated to providing an exceptional experience to our clients at every level.',
        connectivityDescription: 'We believe that connectivity is essential to enable our clients to stay competitive in an ever-evolving market. We offer solutions that allow our clients to connect their different processes, tools, and systems to optimize their operations.',
        sustainability: 'Sustainability',
        sustainabilityDescription: 'We are aware of the impact of our activities on the environment, and we are committed to minimizing our ecological footprint. We constantly seek sustainable solutions for our products and services while contributing to the success of our clients.',
        produits_services: 'Products or Services',
        produit_betooll: 'Our Product: beTool',
        produit_betooll_description: 'We are proud to present our product, beTool, an all-in-one and customizable NoCode business management tool. With beTool, entrepreneurs can easily manage their business processes from a single tool. We offer a smooth and enjoyable administration experience that is always up-to-date with the latest market trends, providing our clients with an optimal user experience.',
        flexibilite: 'Flexibility',
        flexibilite_description: 'We understand that every business is unique, which is why we offer flexible solutions that can be customized to meet the specific needs of each business. With beTool, our clients can choose the modules they want to use, creating a tailor-made solution that meets their needs.',
        connectivite: 'Connectivity',
        connectivite_description: 'We believe that connectivity is essential for the success of businesses today. That\'s why we have developed beTool to easily connect with other business applications and tools. We are committed to providing our clients with an optimal user experience through maximum connectivity.',
        scalabilite: 'Scalability',
        scalabilite_description: 'We understand that business needs can evolve over time, which is why we offer a scalable and customizable solution. Our clients can add or remove modules as their needs change, without having to switch platforms.',
        support_commercial_technique: 'Commercial and Technical Support',
        service_client_qualite: 'Quality Customer Service',
        service_client_qualite_description: 'We take pride in offering exceptional customer service to our clients. Our support team is available to answer questions and assist clients at any time. We are committed to providing an outstanding customer experience to all our clients.',
        service_commercial: 'Commercial Service',
        service_commercial_description: 'Our sales team is here to help clients find the beTool solutions that best suit their needs. We understand that every business has unique needs, and we are determined to find the beTool solution that best meets those needs.',
        consultation_gratuite: 'Free Consultation',
        consultation_gratuite_description: 'We offer a free consultation to help businesses understand how beTool can help them achieve their business goals. Our consultants are available to discuss the company\'s needs and propose beTool solutions that can help solve problems and improve efficiency.',
        formation_support: 'Training and Support',
        formation_support_description: 'We provide comprehensive training to all beTool users to ensure they are using the platform to its fullest potential. Our support team is also available to answer any questions and resolve issues. We are committed to helping businesses maximize their use of beTool and get the most out of it.',
        partenariats_commerciaux: 'Business Partnerships',
        partenariats_commerciaux_description: 'We are open to establishing partnerships with other companies to offer beTool solutions that meet the needs of their clients. If you are interested in a business partnership, feel free to contact us to discuss how we can work together to deliver optimal business management solutions.',


        // nocode
        nocode_title: 'What is noCode? And Who is it For?',
        nocode_description: 'The noCode revolution is transforming how businesses approach application development and IT infrastructure management. This approach democratizes innovation and allows businesses to create custom solutions to meet their specific needs while freeing up developers to work on more complex and high-value projects.',
        intro_title: 'Introduction to noCode',
        intro_description1: 'A noCode software development platform is a powerful tool for businesses looking to quickly develop professional applications without the need for programming knowledge. This platform allows users to create work and project management software using visual elements such as forms, tables, and pre-defined workflows. This means that teams can develop custom applications to meet their unique needs without relying on a developer.',
        intro_description2: 'With a noCode platform, users can build applications in hours instead of weeks or months, saving them time and money. Additionally, applications developed using this platform are often more flexible and adaptable than those built from scratch. Users can easily add and remove features, modify workflows, and customize user interfaces with a few clicks, without changing the application\'s source code.',
        advantages_title: 'Advantages',
        limit_skills: 'No Skill Limitations:',
        cost_reduction2: 'Cost Reduction:',
        flexibility: 'Flexibility:',
        scalability: 'Scalability:',
        market_speed: 'Market Speed:',
        no_maintenance: 'No Maintenance:',
        reasons_title: 'Reasons to Embrace noCode',
        limit_skills_description: 'noCode allows individuals without coding skills to create applications and websites. This means businesses can build internal tools without hiring developers, and individuals can launch projects without investing in costly technical skills.',
        time_saving_point1: 'noCode tools often have ready-to-use features, enabling users to quickly build applications and websites without starting from scratch.',
        time_saving_point2: 'Users can rapidly experiment with ideas and concepts, speeding up the innovation process.',
        time_saving_point3: 'noCode tools also enable faster project delivery, as there is less reliance on third-party development teams.',
        cost_reduction_point1: 'noCode tools are often affordable or even free, significantly reducing development costs for businesses and individuals.',
        cost_reduction_point2: 'Businesses do not need to hire developers to create internal tools, reducing long-term development costs.',
        flexibility_point1: 'noCode tools are often modular and easily adaptable, allowing users to create custom solutions to meet specific needs.',
        flexibility_point2: 'noCode tools can be used to build a variety of solutions, including mobile apps, websites, chatbots, online forms, and more.',
        flexibility_point3: 'noCode tools enable users to easily modify their solutions in real-time without relying on third-party developers.',
        scalability_point1: 'Applications and websites built with noCode tools can easily scale to meet the needs of a growing business.',
        scalability_point2: 'Users can add additional features to their noCode solution to meet the evolving needs of their business or project.',
        integrations_description: 'noCode tools are often designed to easily connect with other applications and services, enabling seamless integration into existing workflows. Integrations streamline processes, increase efficiency, and reduce human errors for businesses.',
        automation_description: 'noCode tools allow for complex process automation without requiring programming knowledge. This enables businesses to save time and money by automating repetitive and time-consuming tasks. Automations can be used for internal processes such as HR management or external tasks like sending personalized emails to clients.',
        market_speed_point1: 'noCode tools often have ready-to-use features, enabling users to quickly build applications and websites without starting from scratch.',
        market_speed_point2: 'Users can rapidly experiment with ideas and concepts, speeding up the innovation process.',
        market_speed_point3: 'noCode tools also enable faster project delivery, as there is less reliance on third-party development teams.',
        no_maintenance_description: 'One of the key benefits of noCode is that it eliminates the need for ongoing maintenance. noCode tools are often managed by third-party providers, meaning they handle updates, security patches, and software maintenance. This allows businesses to focus on their core activities rather than managing their IT infrastructure.',
        target_audience_title: 'Who is noCode For?',
        target_audience_description: 'noCode is for anyone who wants to create applications and automate processes without writing code, whether they are entrepreneurs, IT professionals, office employees, freelancers, or students. noCode tools are designed to be user-friendly and understandable, even for non-technical individuals, making them accessible to a wide audience. noCode offers the opportunity for anyone to be creative and create innovative solutions to meet their specific needs without relying on a team of developers.',
        custom_work_software_title: 'Your Custom Work Software',
        free_trial_description: '15-day Free Trial - No credit card required',
        get_started: 'Get Started',
        contact_title: 'Contact',
        commercial_service: 'Commercial Service',
        by_phone: 'By Phone',
        technical_support: 'Technical Support',
        technical_support_description: 'Technical Support is a service provided to assist customers in resolving technical issues related to a product or service.',
        by_email: 'By Email',
        support_assistance: 'Support Assistance',
        support_assistance_description: 'Support Assistance is a service aimed at helping customers obtain information about products or services, as well as assisting them in the design or development of these products or services.',
        open_ticket: 'Open a Ticket',
        support_standard: 'Standard Support',
        support_standard_description: 'We put your autonomy at the center of our concerns.',
        discover: 'Discover',
        support_premium: 'Premium Support',
        support_premium_description: 'We strive to offer you an optimal experience.',
        support_business: 'Business Support',
        support_business_description: 'Report and track an incident and consult the history.',
        support_enterprise: 'Enterprise Support',
        support_enterprise_description: 'Your satisfaction is always guaranteed.',
        custom_work_software: 'Custom Work Software',
        free_trial_15_days: 'Free Trial 15 Days - No Credit Card Required',
        go: 'Go',

// support
        how_can_we_help: 'Hello, how can we assist you?',
        api_documentation: 'API Documentation',
        use_api_configure_services: 'Use the API to configure services.',
        video_tutorials: 'Video Tutorials',
        train_become_autonomous_platform: 'Train and become autonomous on the platform.',
        support_ticket: 'Support Ticket',
        report_track_incident_consult_history: 'Report and track an incident and consult the history.',
        frequently_asked_questions: 'Frequently Asked Questions',
        how_does_platform_work: 'How does your platform work?',
        platform_works_description: 'Our platform is designed to be simple and intuitive to use. You can start by choosing a pre-defined template and then customize the elements according to your needs. You can also add functionality using pre-designed building blocks or by creating your own components.',
        do_i_need_to_code_to_use_platform: 'Do I need to know how to code to use your platform?',
        no_coding_needed_description: 'No, you do not need to know programming to use our noCode platform. Our platform is designed to be accessible to users of all levels of technical skill. If you have any questions or need assistance, our support team is here to help.',
        what_are_the_pricing_plans: 'What are the pricing plans for your platform?',
        pricing_plans_description: 'We offer a variety of pricing plans to meet the needs of our customers. Our pricing starts at X euros per month for small businesses and individuals, and varies based on the number of projects and features you use. Check out our Pricing section to learn more about our pricing plans and choose the one that best suits your needs.',
        how_can_i_contact_support_team: 'How can I contact your support team if I have questions or issues?',
        contact_support_description: 'If you have any questions or issues with our platform, you can contact our support team via phone, email, or live chat. We are here to assist you at any time and are committed to resolving any issues you may encounter quickly.',
        how_can_i_integrate_third_party_services: 'How can I integrate third-party services into my application?',
        integration_with_third_party_services_description: 'Our platform is designed to easily integrate with a variety of third-party services. We offer a range of pre-built integrations, including popular services like Stripe, Zapier, Aircall, and many others. If you need a custom integration, our support team can help you set up a tailored integration.',
        how_can_i_ensure_security_of_my_application: 'How can I ensure the security of my application?',
        application_security_description: 'Our platform offers a variety of security features to protect your application and data. We use advanced security protocols such as HTTPS, SSL, and OAuth to safeguard sensitive information. We also perform regular data backups to ensure its security in case of any disasters.',
        how_can_i_get_feature_updates_for_my_platform: 'How can I get feature updates for my platform?',
        feature_updates_description: 'We regularly provide feature updates for our platform to continually enhance the user experience and add new capabilities. You can receive update notifications via email or you can check our website to see the latest updates. If you have any suggestions for new features, feel free to let us know.',

        // services

        documentsTitle: 'Documents',
        documentsDescription: 'Document Service - Editing, Storage, and Customization of Your Documents',
        documentsDescriptionPlus: "" +
            "<p>Our Document service revolutionizes the way you manage your documents. It's not just an editing and storage tool. It's a platform designed to help you create customized documents using tags, in various document formats such as .docs, .pdf, and .xlsx.</p>\n" +
            "<h2>Editing and Storage of Documents</h2>\n" +
            "<p>With our Document service, easily edit and store your documents. Whether you want to create a new document or store an existing one, our service is here to assist you.</p>\n" +
            "<h2>Creation of Document Templates</h2>\n" +
            "<p>Our service allows you to create document templates. Whether you need a template for a contract, invoice, or report, our template editing tools will help you create professional documents with ease.</p>\n" +
            "<h2>Use of Tags</h2>\n" +
            "<p>By incorporating tags into your templates, you can create fillable documents with extracted data. Whether you want to include a customer's name, a date, or any other information, our tags make document customization simple and efficient.</p>\n" +
            "<h2>Support for Multiple Document Formats</h2>\n" +
            "<p>Our Document service supports multiple document formats, including .docs, .pdf, and .xlsx. Whatever type of document you want to create or store, our service can meet your needs.</p>\n" +
            "<h2>The Document Service</h2>\n" +
            "<p>The Document service is more than just an editing and document storage tool. It's your partner in creating custom, personalized, and professional documents. Discover a new way to manage your documents with our service.</p>\n",
//
        smsTitle: 'SMS',
        smsDescription: 'SMS Service - Fast and Efficient Communication',
        smsDescriptionPlus: "" +
            "<p>Our SMS service transforms the way you manage your SMS communications. Beyond sending SMS messages, it's a platform designed to automate your sending, create custom templates using tags, and view sending histories.</p>\n" +
            "<h2>Sending SMS via External Providers</h2>\n" +
            "<p>With our SMS service, you can easily send SMS messages through external providers, such as AllMySms. Whether you want to send a message to a customer or an entire team, our service is here to assist you.</p>\n" +
            "<h2>Creation of SMS Templates</h2>\n" +
            "<p>Our service allows you to create SMS templates. Whether you need a message for an appointment reminder or a special announcement, our template creation tools will make writing your messages easier and faster.</p>\n" +
            "<h2>Use of Tags</h2>\n" +
            "<p>By incorporating tags into your templates, you can personalize your SMS messages. Whether you want to include a customer's name, a date, or any other information, our tags make SMS customization simple and efficient.</p>\n" +
            "<h2>Automation of Sending</h2>\n" +
            "<p>With our SMS service, you can automate your sending. Whether you want to send regular reminders or messages based on specific criteria, we make automation easy to save you time.</p>\n" +
            "<h2>Viewing Sending Histories</h2>\n" +
            "<p>With our SMS service, easily access your sending histories. This way, you can always track your communications and maintain a record of all your messages.</p>\n" +
            "<h2>The SMS Service</h2>\n" +
            "<p>The SMS service is more than just a messaging tool. It's your partner for fast, efficient, and personalized communications. Discover a new way to communicate with our service.</p>",


        calendarTitle: 'Calendar',
        calendarDescription: 'A Calendar Full of Surprises',
        calendarDescriptionPlus: "" +
            "<p>Our Calendar service allows you to easily manage your events and appointments. Whether it's for meetings, client appointments, or team events, you have a centralized tool to organize and schedule your activities.</p>\n" +
            "<h2>Advanced Scheduling Features</h2>\n" +
            "<p>Our Calendar service offers a multitude of advanced features to optimize your scheduling. You can benefit from customizable views, reminders and notifications, availability management, and the ability to share calendars with other users.</p>\n" +
            "<h2>Integration with the MAP Service</h2>\n" +
            "<p>The Calendar service is closely linked to the MAP service, allowing you to view distances between appointments and optimize your travel. You can visualize routes between different appointment locations, helping you make informed decisions and maximize your efficiency.</p>\n" +
            "<h2>Synchronization with Other Calendars</h2>\n" +
            "<p>Our Calendar service also offers the ability to synchronize your events with other calendars, such as Google Calendar. This allows you to centralize your activities and facilitate the management of your schedule.</p>\n" +
            "<h2>The Calendar Service</h2>\n" +
            "<p>The Calendar service is more than just a time management tool. It's your partner for advanced planning, optimized appointment management, and convenient synchronization with other calendars. Discover how our Calendar service can improve your time and activity management.</p>\n",

        accountingTitle: 'Accounting',
        accountingDescription: 'Accounting Service - Easy Management of Quotes, Invoices, and Credits',
        accountingDescriptionPlus: "" +
            "<p>Our Accounting service provides you with a comprehensive solution for efficiently managing your quotes, invoices, and credits. With our service, you can easily edit professional documents, choose from a selection of visual templates, or create your own customized templates. We provide you with an application and widgets to facilitate access to relevant information. Additionally, our service allows you to connect your payment methods such as Stripe to streamline payment management.</p>\n" +
            "<h2>Edit Quotes, Invoices, and Credits</h2>\n" +
            "<p>With our Accounting service, you can quickly and efficiently edit your quotes, invoices, and credits. Whether you need to create a quote for a client, generate an invoice for a sale, or produce a credit for a refund, our service simplifies all these operations.</p>\n" +
            "<h2>Visual Templates or Custom Template Creation</h2>\n" +
            "<p>Our service offers you the flexibility to choose from a selection of visual templates for your quotes, invoices, and credits. You can also create your own custom templates to meet the specific needs of your business. With our intuitive editor, template creation is easy and allows you to customize the information and appearance of your documents.</p>\n" +
            "<h2>Application and Widgets for Easy Access</h2>\n" +
            "<p>We provide you with a dedicated application and widgets for easy and quick access to accounting information. Whether you are in the office, on the go, or using your mobile device, you can access your quotes, invoices, credits, and payment information in one place.</p>\n" +
            "<h2>Connecting Payment Methods such as Stripe</h2>\n" +
            "<p>Our Accounting service allows you to connect your payment methods, such as Stripe. This streamlines payment management, enabling you to send electronic invoices with integrated payment links and track payment transactions in real-time.</p>\n" +
            "<h2>The Accounting Service</h2>\n" +
            "<p>The Accounting service is more than just a financial document management tool. It's your partner for easy management of quotes, invoices, and credits, with an application and widgets for easy access to essential information. Discover how our Accounting service can simplify your accounting and optimize your financial process.</p>\n",


        formTitle: 'Form',
        formDescription: 'Form Service - Facilitator of Experiences and Connections',
        formDescriptionPlus: "" +
            "<p>With our Form service, rethink how you manage your procedures and customer experience. More than just a form builder, it's an innovative platform designed to help you simplify the connections between data and their architecture.</p>\n" +
            "<h2>Easy-to-Integrate Form Creation</h2>\n" +
            "<p>Our service allows you to easily create forms to integrate into your procedures. Whether you want to collect customer information or structure an internal process, our form system makes form creation and integration easy.</p>\n" +
            "<h2>Various Form Formats</h2>\n" +
            "<p>We offer multiple form formats, giving you the flexibility to meet your specific needs. Whether it's text, numbers, dates, or multiple-choice options, our service supports a variety of formats.</p>\n" +
            "<h2>Management of Functions and Formulas</h2>\n" +
            "<p>Forms are not just a means of collecting information. They can also be a powerful platform for calculations and logic. With our service, you can manage functions and formulas in your forms, enhancing their functionality and usefulness.</p>\n" +
            "<h2>Sharing Templates on Our Shared Space</h2>\n" +
            "<p>Have you created an impressive form? Share it with others on our shared template space. Our Form service encourages the sharing of best practices and collaborative inspiration.</p>\n" +
            "<h2>The Form Service</h2>\n" +
            "<p>The Form service is more than just a form builder. It's your partner in facilitating customer experience and data management. Experience smarter form management with our service.</p>\n",


        productsTitle: 'Products',
        productsDescription: 'Product Catalog Management',
        productsDescriptionPlus: "  " +
            "<p>Rethink how you manage your product catalogs with our Products service. It's not just a product management tool, it's a comprehensive platform designed to help you maximize the efficiency and flexibility of your catalog.</p>\n" +
            "<h2>Product Category Management</h2>\n" +
            "<p>We understand that clarity and organization are essential when it comes to managing a product catalog. That's why our service allows you to structure your products into different categories, making navigation and discovery easier for your customers.</p>\n" +
            "<h2>Advanced Product Configuration</h2>\n" +
            "<p>Our service offers advanced configuration options for each product. Whether it's managing pricing tiers, applying dynamic data, configuring taxes, adding photos, or even associating a PDF document, our system provides the flexibility to configure your products with precision and detail.</p>\n" +
            "<h2>The Products Service</h2>\n" +
            "<p>The Products service is more than just a catalog management tool. It's your partner in making your product management simpler, more efficient, and more flexible. Experience product management at a higher level with our service.</p>\n",


        emailDescription: "Email Service - Simplified and Optimized Communication",
        emailDescriptionPlus:
            "  <p>Rethink how you manage your email communication with our Email service. It's not just a tool for sending and receiving emails. It's a platform designed to maximize your productivity and improve the efficiency of your exchanges.</p>\n" +
            '  <h2>Send and Receive Emails with Ease</h2>\n' +
            "  <p>Our service allows you to send and receive emails with ease. Whether you need to send a quick update or manage a complex conversation, our Email service is here to help.</p>\n" +
            '  <h2>Create Custom Templates</h2>\n' +
            "  <p>Benefit from the flexibility of a text editor and a drag-and-drop editor to design personalized email templates. Whether you want to create an attractive newsletter or a professional follow-up email, our template creation tools will make it easy for you.</p>\n" +
            '  <h2>Automate Email Sending</h2>\n' +
            "  <p>Say goodbye to the tedious task of manually sending emails. With our Email service, you can automate your sending. Whether it's regular newsletters or automated responses, we make automation easy so you can spend more time on what really matters.</p>\n" +
            '  <h2>Access Histories in All Contexts</h2>\n' +
            "  <p>Communication history is essential for maintaining continuity and efficiency in your exchanges. With our Email service, easily access your email histories in all contexts, ensuring a record of every conversation.</p>\n" +
            '  <h2>The Email Service</h2>\n' +
            "  <p>The Email service is more than just an email tool. It's your communication partner, designed to make your exchanges simpler, more efficient, and more meaningful. Experience the evolution of email communication with our service.</p>\n",


        leadsDescription: "Leads Service - Optimized Lead Management",
        leadsDescriptionPlus:
            "  <p>Our Leads service transforms the way you manage your leads. Beyond a simple management tool, our system is a comprehensive solution designed to help you maximize your conversion efforts.</p>\n" +
            '  <h2>Dedicated Spaces for Lead Management</h2>\n' +
            "  <p>We understand that every interaction with a lead is an opportunity not to be missed. That's why our Leads service allows you to create dedicated spaces specifically for lead management. These spaces are designed to help you efficiently track each lead, manage interactions, schedule follow-ups, and ultimately convert leads into customers.</p>\n" +
            '  <h2>Management of Multiple Lead Spaces</h2>\n' +
            "  <p>Whether you run a multi-faceted business or work with different marketing campaigns, the ability to manage multiple lead spaces is crucial. Our system provides you with this flexibility. You can set up and manage as many lead spaces as needed, each tailored to a specific activity or campaign. This allows you to track and manage your leads in an organized and targeted manner.</p>\n" +
            '  <h2>Experience the Evolution of Lead Management</h2>\n' +
            "  <p>With our Leads service, lead management is no longer a tedious task. It's an optimized process that helps you achieve your conversion goals faster and more efficiently. Experience the evolution of lead management with our service.</p>",

        esignDescription: "Electronic Signature Service - Simplify Your Signing Processes",
        esignDescriptionPlus:
            " <p>With our Electronic Signature service, transform the way you manage your signing processes. Beyond a simple signing tool, it's a comprehensive solution designed to help you optimize and secure your transactions.</p>\n" +
            '  <h2>Sending Signatures via Email or SMS</h2>\n' +
            "  <p>Our service allows you to send signature requests via email or SMS. Regardless of the channel your clients prefer, our system provides the necessary flexibility to reach your recipients effectively and conveniently.</p>\n" +
            '  <h2>Tracking Signature Events</h2>\n' +
            "  <p>Once you've sent a signature request, our service gives you an overview of the events associated with that request. You can track which documents have been signed and which ones have been declined, giving you complete visibility into the status of your transactions.</p>\n" +
            '  <h2>Integration with Various Applications</h2>\n' +
            "  <p>Depending on the plugin you use, our Electronic Signature service can be integrated with various applications, providing an additional level of flexibility and efficiency in managing your signing processes.</p>\n" +
            '  <h2>The Electronic Signature Service</h2>\n' +
            "  <p>The Electronic Signature service is more than just a signing tool. It's your partner in simplifying and securing your signing processes. Discover how our service can help you optimize your transactions.</p>\n",



        mapDescription: "MAP Service - Optimized Location Management and Planning",
        mapDescriptionPlus:
            " <p>With our MAP service, reinvent the way you manage location and resource planning. Beyond a simple map visualization tool, it's a robust solution that facilitates the management of geolocated objects or records.</p>\n" +
            '  <h2>Management of Objects or Records on the Map</h2>\n' +
            "  <p>Our service allows you to easily manage objects or records on a map view. Whether you need to track a package or manage a fleet of vehicles, our MAP service makes location tracking easier and more intuitive.</p>\n" +
            '  <h2>Distance Calculation</h2>\n' +
            "  <p>Need to know the distance between two points on the map? Our MAP service can calculate that for you. Whether you're planning a delivery route or mapping out a road trip, our distance calculation system makes planning easier.</p>\n" +
            '  <h2>Improved Planning</h2>\n' +
            "  <p>By integrating the MAP service with your calendar, you can improve your planning. Whether you're coordinating field team routes or planning your own itinerary, our MAP service can help optimize your routes and maximize efficiency.</p>\n" +
            '  <h2>The MAP Service</h2>\n' +
            "  <p>The MAP service is more than just a mapping tool. It's your partner for smarter location management and optimized planning. Discover how our service can enhance your location and planning operations.</p>\n",


        telephonyDescription: "IP Telephony Service - Simplified Connectivity and Call Access",
        telephonyDescriptionPlus:
            " <p>With our IP Telephony service, transform the way you manage your phone communications. It's not just an internet telephony service. It's an integrated platform designed to connect your IP telephony provider and improve your accessibility.</p>\n" +
            '  <h2>Connection with Your IP Telephony Provider</h2>\n' +
            "  <p>Our service allows you to easily connect your IP telephony provider through plugins. This makes managing your phone communications easier, whether it's an important call with a client or a conference call with your team.</p>\n" +
            '  <h2>Accessible Call History Everywhere</h2>\n' +
            "  <p>With our service, you can access your call history wherever you are. Whether you're working remotely or on the go, you can review your call history, ensuring continuity in your communications.</p>\n" +
            '  <h2>Click to Call</h2>\n' +
            "  <p>Simplify how you make calls with our Click to Call feature. Instead of manually dialing a number, you can simply click on a contact to call them. It saves time and minimizes dialing errors.</p>\n" +
            '  <h2>Download Call Audio Recordings</h2>\n' +
            "  <p>Our IP Telephony service also allows you to download call audio recordings. Whether you want to review an important conversation or keep a record of a call, you can easily download the audio recording.</p>\n" +
            '  <h2>The IP Telephony Service</h2>\n' +
            "  <p>The IP Telephony service is more than just an internet telephony service. It's your ally for smoother and more accessible call management. Discover the evolution of phone communications with our service.</p>\n",

        contactCompanyDescription: "Contact and Company Service - Centralization of Information and Optimization of Views",
        contactCompanyDescriptionPlus:
            "<p>With our Contact and Company service, revolutionize the way you manage and visualize your data. This service is not just a contact or company management tool. It's a platform designed to centralize information around an entity and optimize 360-degree views.</p>\n" +
            '  <h2>Focus on Procedures</h2>\n' +
            "  <p>Our service allows you to focus on procedures such as a contact or company. This allows you to centralize all relevant information and have a complete and up-to-date view of each entity.</p>\n" +
            '  <h2>360-Degree View</h2>\n' +
            "  <p>With our service, you can benefit from a 360-degree view for each entity. Whether you need to visualize all interactions with a contact or understand the complete structure of a company, our 360-degree view provides you with a comprehensive and detailed understanding.</p>\n" +
            '  <h2>Zone Management</h2>\n' +
            "  <p>The Contact and Company service also allows you to separate your focus into zones, which is particularly useful for managing multiple companies. This enables you to efficiently manage and organize your data, ensuring that each company or contact remains clearly distinct and easily accessible.</p>\n" +
            '  <h2>The Contact and Company Service</h2>\n' +
            "  <p>The Contact and Company service is more than just a contact or company management tool. It's your partner for better organization and visualization of your data. Discover a new way to manage your information with our service.</p>\n",


        servicess: 'Services',
        getYourAccess: 'Get your access now!',
        freeTrial:'Free Trial',
        signup: 'Sign up',


// pricing
        EnsembleProductivite: "Together, let's increase your productivity",
        CommencezGratuitement: "Start for free",
        PeriodeEssai: "15-day trial period - No credit card required",
        annual: 'Annual',
        monthly: 'Monthly',
        productivity: 'Let\'s boost your productivity together',
        freeStart: 'Start for free',
        trialPeriod: '15-day trial period - No credit card required',
        solo: 'Solo',
        price: '€0',
        freeLifetime: 'Free for lifetime',
        individualPlanIncludes: 'The individual plan includes:',
        collaborativeWorkspace: 'Collaborative workspace',
        freeAPIIntegration: 'Free API integration',
        limitedTimeHistory: 'Limited time history (7 days)',
        powerfulFormulas: 'Powerful formulas and automations',
        basic: 'Basic',
        perUserMonth: 'per user/month',
        includesIndividualPlus: 'Includes individual plan, plus:',
        priorityCustomerService: 'Priority customer service',
        fileStorage: '5 GB file storage',
        limitedTimeHistory30: 'Limited time history (30 days)',
        unlimitedItems: 'Unlimited items',
        basicPermissionManagement: 'Basic permission management',
        recommended: 'Recommended',
        standard: 'Standard',
        includesBasicPlus: 'Includes basic plan, plus:',
        calendarView: 'Calendar view',
        unlimitedAutomations: 'Unlimited automations',
        limitedTimeHistory7: 'Limited time history (7 days)',
        VIPServiceManagement: 'VIP service management',
        permissionManagementAllLevels: 'Permission management at all levels',
        pro: 'Pro',
        includesStandardPlus: 'Includes standard plan, plus:',
        documentVisualizationSAS: 'Document visualization SAS (via QR code)',
        fileStorage15: '15 GB file storage',
        premiumSupport: 'Premium support',
        createDashboard: 'Create a dashboard combining up to 50 boards',
        enterprise: 'Enterprise',
        custom: 'Custom',
        includesProPlus: 'Includes pro plan, plus:',
        unlimitedPlugins: 'Unlimited plugins',
        unlimitedFileStorage: 'Unlimited file storage',
        unlimitedSetupIntegration: 'Unlimited setup and integration',
        unlimitedTimeHistory: 'Unlimited time history',
        onDemandDevelopment: 'On-demand development',
        note: 'Note',
        someAppsNote: 'Some of the applications or plugins offer additional plans at announced rates in advance. For more information on pricing for these applications, click',
        here: 'here',
        customWorkSoftware: 'Your custom work software',
        freeTrialNote: '15-day free trial - No credit card required',
        goThere: 'Go there',


        // views
        advantages_of_kanban: 'Advantages of Kanban View',
        task_visualization: 'Task Visualization',
        task_visualization_description: 'The Kanban view allows for easy visualization of all project tasks at a glance. Tasks are represented as cards that can be moved from one column to another to indicate their progress. This clear and concise visualization helps to better understand the project as a whole and coordinate team efforts more effectively.',
        productivity_optimization: 'Productivity Optimization',
        productivity_optimization_description: 'The Kanban view optimizes productivity by quickly identifying tasks that are blocked or require special attention. Team members can react promptly to resolve issues and maintain a steady work pace. Additionally, by reducing the time needed to track task progress, team members can focus more on task completion itself.',
        theFlexibility: 'Flexibility',
        flexibility_description: 'The Kanban view allows for easy rearrangement of tasks based on their priority or urgency. Cards can be easily moved from one column to another, enabling quick adaptation to changing circumstances. This flexibility is particularly valuable in dynamic work environments where priorities can shift rapidly.',
        drag_and_drop: 'Drag and Drop',
        customizable_card: 'Customizable Card',
        datatable: 'Data Table',
        advantages_datatable: 'Here are the advantages of managing with a DataTable view:',
        advantage_1: 'Efficient Sorting and Filtering:',
        advantage_1_desc: 'The DataTable view enables efficient sorting and filtering of data, allowing users to quickly find the information they need. Moreover, the DataTable view supports multiple filters that can be combined for even more precise results.',
        advantage_2: 'Data Pagination:',
        advantage_2_desc: 'The DataTable view can also paginate data, dividing the results into multiple pages and loading only the necessary data each time. This reduces loading time and improves application performance.',
        advantage_3: 'Appearance Customization:',
        advantage_3_desc: 'The DataTable view allows for easy customization of data appearance, which can be valuable for businesses looking to adapt their user interface to their brand or specific work style. Users can customize color, font, size, and layout of data based on their preferences.',
        feature_1: 'Drag and Drop',
        feature_2: 'Customizable Card',
        advantages_calendar: 'Here are the advantages of managing with a Calendar view:',
        advantage_cal1: 'Global Visualization:',
        advantage_cal1_desc: 'The Calendar view provides a global visualization of upcoming tasks, appointments, or events. This allows users to plan their time more effectively by having an overview of what needs to be accomplished.',
        advantage_cal2: 'Easy Scheduling:',
        advantage_cal2_desc: 'With the Calendar view, users can easily schedule tasks, appointments, or events by simply dragging and dropping them to the appropriate date and time. This saves time and increases productivity.',
        advantage_cal3: 'Sharing and Collaboration:',
        advantage_cal3_desc: 'The Calendar view can also facilitate the sharing of schedules or agendas with team members or external partners. This eases collaboration and coordination of tasks and projects.',
        imageURL: 'https://www.objectif-crm.com/assets/images/vueCalendrier.PNG',
        be_ready: 'BE READY FOR MORE',
        get_your_access: 'Get your access now!',
        start_trial: 'Start Trial',
        request_callback: 'Request Callback',

        // integrations
        ACtitle: 'Connected Business Telephony to Your Tools',
        integrationBenefits: 'Integrating Aircall can have many benefits for your business. Here are some reasons why you should consider integrating Aircall:',
        customerExperience: '<strong>Improved Customer Experience:</strong> By integrating Aircall, you can provide a more personalized and efficient customer experience. When a customer calls, you can easily access their order history, preferences, and previous interactions with your business, allowing you to provide tailored service to their needs.',
        taskAutomation: '<strong>Task Automation:</strong> Aircall integration can help automate certain tasks, such as creating new contacts and updating customer records. This can save time and reduce data entry errors.',
        performanceTracking: '<strong>Performance Tracking:</strong> By integrating Aircall, you can track real-time performance of your sales and customer service team. You can monitor key metrics such as call volume, call duration, response rate, and conversion rate to continuously improve your team\'s performance.',
        enhancedCollaboration: '<strong>Enhanced Collaboration:</strong> Aircall integration can facilitate collaboration among your team members by centralizing all customer information in one place. Team members can easily access updated customer information and communicate with each other to provide a consistent customer experience.',
        summary: 'In summary, integrating Aircall can help your business deliver an improved customer experience, automate tasks, track performance, and enhance collaboration among team members.',

        RHtitle: 'Reach and Engage Your Customers Like Never Before',
        integrationBenefits2: 'Integrating Ringover can offer several benefits for your business, including:',
        costReduction: '<strong>Cost Reduction:</strong> By integrating Ringover, you can reduce costs related to phone calls. Ringover offers competitive rates for international calls, which can be advantageous if you have customers in different countries. Additionally, integration can reduce travel costs by providing features such as conference calls and video conferencing.',
        customerPersonalization: '<strong>Customer Experience Personalization:</strong> By integrating Ringover, you can personalize the customer experience based on each customer\'s preferences. For example, you can identify VIP customers and provide them with personalized service or use call scripts to address specific needs of each customer.',
        productivityImprovement: '<strong>Productivity Improvement:</strong> Ringover integration can improve your team\'s productivity by providing easy and quick access to all customer information. Your teams can spend less time searching for information and more time focusing on high-value tasks such as sales and issue resolution.',
        summary2: 'In summary, integrating Ringover can help you improve the customer experience, automate tasks, track performance, and enhance collaboration among your team members. Additionally, it can reduce costs related to phone calls, personalize the customer experience, and improve your team\'s productivity. Ultimately, Ringover integration can help you deliver an exceptional customer experience and improve your business efficiency.',

        MAPtitle: 'All About Maps',
        integrationBenefits3: 'Integrating Google Maps can offer several benefits for your business, including:',
        customerLocalization: '<strong>Customer Localization:</strong> By integrating Google Maps, you can easily visualize the location of your customers and prospects on a map. This can help you plan in-person visits or make informed decisions on how best to handle interactions with your customers.',
        travelOptimization: '<strong>Travel Optimization:</strong> Google Maps integration can also help you optimize travel for your sales or customer service teams. You can use the routes generated by Google Maps to plan representatives\' visits based on their location and that of your customers, reducing travel time and improving your team\'s productivity.',
        dataAccuracy: '<strong>Improved Data Accuracy:</strong> By integrating Google Maps, you can enhance the accuracy of customer data by adding location information. This can help better segment your customer base and personalize interactions based on their geographic location.',
        dataVisualization: '<strong>Data Visualization:</strong> Google Maps integration can also help you visualize your customer data on a map. This can enable better understanding of geographic trends in your business, such as areas with high customer concentration or areas where you need to expand your presence.',
        customerExperience3: '<strong>Improved Customer Experience:</strong> By integrating Google Maps, you can offer an enhanced customer experience by providing relevant geographic information. For example, you can provide driving directions to your customers when scheduling appointments or offer recommendations for points of interest near their location.',
        summary3: 'In summary, integrating Google Maps can help you locate customers, optimize travel, improve data accuracy, visualize data, and deliver an enhanced customer experience. This integration can assist your business in understanding geographic trends, optimizing customer interactions, and offering a more personalized and relevant customer experience.',


        SPtitle: 'Infrastructure Fit for the Internet',
        integrationBenefits4: 'Integrating Stripe can offer several benefits for your business, including:',
        paymentFacilitation: '<strong>Payment Facilitation:</strong> By integrating Stripe, you can easily accept online payments from your customers. This can streamline transactions and enhance the customer experience, such as allowing customers to pay directly from their invoice or customer account.',
        paymentAutomation: '<strong>Payment Process Automation:</strong> Stripe integration can also help automate payment processes. For example, you can set up automated reminders for overdue payments or automate recurring billing.',
        paymentSecurity: '<strong>Payment Security:</strong> Stripe is known for its high-level payment security. By integrating Stripe, you can provide your customers with a secure payment method, giving them peace of mind.',
        paymentTracking: '<strong>Payment Tracking:</strong> Stripe integration can help you track payments and view real-time sales data. This can provide insights into sales trends and allow you to adjust your strategy accordingly.',
        toolIntegration: '<strong>Integration with Other Tools:</strong> Stripe easily integrates with other tools and applications, such as e-commerce platforms or marketing tools. By integrating Stripe, you can centralize sales data and streamline payment processes across different platforms.',
        summary4: 'In summary, integrating Stripe can facilitate payments, automate payment processes, ensure payment security, track payments in real-time, and integrate Stripe with other tools. This integration can help your business simplify financial transactions, improve the customer experience, and optimize sales processes.',

        GDtitle: 'Document Storage',
        integrationBenefits5: 'Integrating Google Drive can offer several benefits for your business, including:',
        centralizedStorage: '<strong>Centralized Storage:</strong> By integrating Google Drive, you can centralize all documents and files related to your clients or projects in one place. This can facilitate collaboration and project management for your team.',
        quickAccess: '<strong>Quick Access to Documents:</strong> Google Drive integration can enable quick access to relevant documents and files directly from your CRM. You no longer need to search for documents in different folders or applications.',
        realTimeCollaboration: '<strong>Real-time Collaboration:</strong> Google Drive allows for real-time collaboration on documents and files. By integrating Google Drive, you can facilitate collaboration among team members or with clients.',
        dataBackupSecurity: '<strong>Data Backup and Security:</strong> Google Drive offers secure storage and real-time data backup. By integrating Google Drive, you can ensure that data related to your clients or projects is backed up and secured.',
        toolIntegration5: '<strong>Integration with Other Tools:</strong> Google Drive easily integrates with other tools and applications, such as project management tools or messaging tools. By integrating Google Drive, you can centralize data related to your clients and projects and streamline collaboration processes across different platforms.',
        summary5: 'In summary, integrating Google Drive can facilitate file storage and management, provide quick access to relevant documents, enable real-time collaboration, ensure data security, and streamline collaboration processes across different platforms. This integration can help your business improve efficiency and productivity for your team, as well as customer satisfaction.',

        YStitle: 'Electronic Signature',
        integrationBenefits6: 'Integrating Yousign can offer several benefits for your business, including:',
        simplifiedContractManagement: '<strong>Simplified Contract Management:</strong> By integrating Yousign, you can simplify contract and document management related to your clients or projects. You can create, sign, and track contracts directly within the tool, eliminating the need for manual and time-consuming processes.',
        reducedSigningDelays: '<strong>Reduced Signing Delays:</strong> Yousign offers a fast and secure electronic signature solution, which can help reduce contract signing delays. By integrating Yousign, you can expedite the signing process and improve your business\'s efficiency.',
        improvedProductivity: '<strong>Improved Productivity:</strong> By simplifying contract management and reducing signing delays, integrating Yousign can help improve productivity for your business and team.',
        documentSecurity: '<strong>Document Security:</strong> Yousign provides a secure electronic signature solution, ensuring the authenticity and integrity of signed documents. By integrating Yousign, you can secure documents related to your clients and projects while simplifying their management.',
        toolIntegration6: '<strong>Integration with Other Tools:</strong> Yousign easily integrates with other tools and applications, such as project management tools or messaging tools. By integrating Yousign, you can centralize data related to your clients and projects and streamline collaboration processes across different platforms.',
        summary6: 'In summary, integrating Yousign can simplify contract management, reduce signing delays, improve productivity for your business, and ensure the security of documents related to your clients and projects. This integration can help your business enhance your team\'s efficiency and customer satisfaction.',

        AMStitle: 'SMS Messaging',
        integrationBenefits7: 'Integrating AllMySms can offer several benefits for your business, including:',
        improvedCommunication: '<strong>Improved Communication with Customers:</strong> By integrating AllMySms, you can effectively and quickly communicate with your customers. You can send mass SMS messages for promotions, notifications, or reminders directly from your CRM.',
        increasedOpenRate: '<strong>Increased Open Rate:</strong> SMS messages have a high open rate, which can increase the visibility of your business and offers. By integrating AllMySms, you can improve the open rate of your messages and optimize your communication campaigns.',
        automatedSending: '<strong>Automated Sending:</strong> AllMySms offers automated sending features, allowing you to schedule and send SMS messages based on predefined scenarios. By integrating AllMySms, you can automate SMS sending for tasks such as appointment confirmations, payment reminders, or customer birthdays.',
        messagePersonalization: '<strong>Message Personalization:</strong> AllMySms allows for personalized messages using variables such as customer name or transaction amount. By integrating AllMySms, you can customize messages based on data stored in your CRM, providing a more personalized customer experience.',
        dataCentralization: '<strong>Data Centralization:</strong> By integrating AllMySms, you can centralize data related to communication with your customers, facilitating the management of interactions and responses. You can have an overview of communications with your customers directly from your CRM.',
        summary7: 'In summary, integrating AllMySms can improve communication with your customers, increase message open rates, automate SMS sending, personalize messages, and centralize data related to communication. This integration can help your business improve communication efficiency with customers and provide a more personalized customer experience.',


        DStitle: 'Electronic Signature',
        integrationBenefits8: 'Integrating DocuSign can offer several benefits for your business, including:',
        electronicSignature8: '<strong>Electronic Signature:</strong> With DocuSign, you can obtain electronic signatures for documents directly from your CRM. This can reduce document processing time and improve your business\'s efficiency.',
        documentCentralization: '<strong>Document Centralization:</strong> By integrating DocuSign into your organization, you can centralize documents related to transactions with your clients. This can facilitate document management and traceability of exchanges with clients.',
        processAutomation: '<strong>Process Automation:</strong> DocuSign allows for automation of processes related to document signing, saving time and improving your business\'s efficiency. By integrating DocuSign, you can automate signature and document management processes to provide a faster and more efficient customer experience.',
        integrationWithOtherApps: '<strong>Integration with Other Applications:</strong> DocuSign can be integrated with other applications such as Salesforce, Microsoft Dynamics, and SharePoint. By integrating DocuSign, you can streamline the management of document signing processes and enhance the interoperability of your applications.',
        dataSecurity: '<strong>Data Security:</strong> DocuSign offers advanced security features to protect sensitive customer and business data. By integrating DocuSign, you can ensure the security of data related to client transactions.',
        summary8: 'In summary, integrating DocuSign can provide electronic signatures, centralize documents, automate processes, facilitate integration with other applications, and ensure data security. This integration can help your business improve the efficiency of document signing processes, provide a faster and more efficient customer experience, and ensure data security.',

        customSoftware: 'Your Custom Work Software',
        getStarted: 'Get Started',

        // Esign
        theFuture: "The Future",
        electronicSignatures: "Electronic Signatures: The Future of Business Transactions",
        electronicSignatureDescription1: "Electronic signature is a powerful tool for businesses looking to improve their document signing processes.",
        electronicSignatureDescription2: "More and more companies are adopting electronic signatures for these reasons, and it has become clear that it is the future of business transactions. We are confident that electronic signature is an essential tool to help businesses stay competitive in the market.",
        powerAndPerformance: "Power and Performance...",
        electronicSignaturePower: "Electronic signature is a powerful tool for businesses looking to improve their document signing processes. Indeed, electronic signature helps reduce document processing times, increase data security, and facilitate the management of business transactions.",
        electronicSignatureFuture: "More and more companies are adopting electronic signatures for these reasons, and it has become clear that it is the future of business transactions. As a provider, we are confident that electronic signature is an essential tool to help businesses stay competitive in the market.",
        Avantages: "Advantages",
        LesAvantagesSignatureElectronique: "The advantages of integrating electronic signature",
        IntegrerSignatureElectroniqueDescription1: "Integrating electronic signature into your work tool can offer several advantages for your business, including:",
        AvantageReductionDelais: "Reduction of document processing times",
        AvantageAugmentationSecurite: "Increase in data security",
        AvantageFacilitationGestion: "Facilitation of business transaction management",
        AvantageAmeliorationExperience: "Improvement of customer experience",
        AvantageOptimisationProcessus: "Optimization of document signing processes",
        IntegrerSignatureElectroniqueDescription2: "By integrating electronic signature into your work tool, you can improve the efficiency of your document signing processes, provide a faster and more efficient customer experience, and ensure the security of data related to business transactions.",
        ContactezNousEnSavoirPlus: "Contact us to learn more",
        ContactezNousDescription: "If you would like to learn more about our electronic signature solution, please don't hesitate to contact us. We will be happy to answer all your questions and help you improve your processes with electronic signature.",
        DemandeRappel: "Request a callback",
        SignatureElectronique: "Electronic Signature",


        titleTesti:'Testimonials',

        Support: "Support",
        SupportStandard: "Standard Support",
        SupportStandardDescription: "Standard Support is our basic level of assistance included for all users. Our dedicated team is available to address your technical questions via email and through our 24/7 online help center. You will also have access to a vast library of self-help resources, including guides, video tutorials, and a detailed FAQ.",
        SupportPremium: "Premium Support",
        SupportPremiumDescription: "Premium Support goes above and beyond standard support. In addition to all the benefits of standard support, premium users receive priority response to their support requests, ensuring a quick response from our support team. You will also have access to live training sessions and real-time chat assistance.",
        SupportBusiness: "Business Support",
        SupportBusinessDescription: "Business Support is designed for medium-sized organizations that require more in-depth assistance. In addition to all the benefits of premium support, business users have a dedicated account manager who will understand your specific needs and work with you to optimize the use of our platform in your organization. Additionally, you will have access to customized training for your team and phone support during business hours.",
        SupportEnterprise: "Enterprise Support",
        SupportEnterpriseDescription: "Enterprise Support is our most comprehensive support level, designed for large organizations that require specialized assistance. In addition to all the benefits of business support, enterprise users receive a guaranteed two-hour response time for urgent support requests. Additionally, you will have access to 24/7 phone support, application health audits, and strategic planning sessions with our executive team. Your success is our priority, and we will do everything we can to help you achieve your goals.",
        SupportRequest: "Request Support",
        CreateTicket: "Create a Ticket",


        TermsOfUse: "Terms of Use",
        EffectiveDate: "Effective Date: 01/01/2023",
        TermsDescription0: "The present general terms and conditions govern the use of betool, the intellectual property of Technician Soft Solution, and marketed by Soft Group Technologies. Technician Soft Solution is the exclusive owner of all intellectual property rights related to betool. SASU Soft Group Technologies SIREN 921 865 358, a company governed by French law, is responsible for the marketing of betool. The use of betool is subject to the acceptance of these conditions, which include the license terms granted by Technician Soft Solution and the terms of sale and service provided by Soft Group Technologies.",
        TermsDescription1: "These terms of use (referred to as 'Terms') govern the legal framework for the provision of the website and services by https://www.betool.fr and define the conditions for accessing and using the services by the 'User'.",
        TermsDescription2: "These Terms are accessible on the website under the 'Terms' section.",
        TermsDescription3: "Any registration or use of the website implies the User's acceptance without reservation or restriction of these Terms. By registering on the website via the Registration Form, each user expressly accepts these Terms by checking the box preceding the following text: 'I acknowledge that I have read and understood the Terms and I accept them'. In case of non-acceptance of the Terms stipulated in this agreement, the User must refrain from accessing the services offered by the website. www.betool.fr reserves the right to unilaterally and at any time modify the content of these Terms.",
        TermsDescription4: "https://www.betool.fr reserves the right to unilaterally and at any time modify the content of these Terms.",

        IntroductionTitle: "1. Introduction",
        IntroductionDescription: "Welcome to our Platform. As a software provider, we offer user licenses, application licenses, and assistance services for configuring various elements such as architecture, documents, templates, APIs, etc. Our solution is entirely noCode, allowing our users to create customized applications without writing a single line of code.",
        IntroductionTerms: "These terms of use (referred to as 'Terms') aim to define the terms of use of our Platform and the services that we offer.",
        IntroductionUsage: "By accessing and using our Platform and our services, you unconditionally accept these Terms. Please read them carefully before continuing to use our Platform and our services.",
        IntroductionAcceptance: "Please read them carefully before continuing to use our platform and services.",

        DefinitionsTitle: "2. Definitions",
        Fournisseur: "Supplier",
        FournisseurDescription: "refers to our company, a SaaS software provider of (Work system, Work OS, CRM, ERP, Project Management, and Ticketing solutions) in full noCode, offering services such as user licenses, application licenses, and assistance services for configuring various elements (architecture, documents, templates, APIs, etc.).",
        Client: "Client",
        ClientDescription: "refers to any individual or legal entity that uses the services offered by our company, whether as an end user or as an administrator authorized by the end user.",
        Utilisateur: "User",
        UtilisateurDescription: "refers to any individual or legal entity that uses the website and/or services offered by our company.",
        Site: "Site",
        SiteDescription: "refers to the website https://yourwebsite.com and its subdomains, as well as any mobile application or other platform used to access the services offered by our company.",
        Services: "Services",
        ServicesDescription: "refers to all the services offered by our company, such as the licenses to use our software, the assistance services for configuration and customization of our solutions, and any other service provided by our company.",
        LicenceUtilisateur: "User License",
        LicenceUtilisateurDescription: "refers to the authorization to use our software and its features, granted by our company to the User upon payment of a subscription or fee.",
        LicenceApplication: "Application License",
        LicenceApplicationDescription: "refers to the authorization to use an application developed by our company to be integrated into the User's software, granted by our company upon payment of a subscription or fee.",

        objectTitle: "3. Purpose of the Terms",
        objectDescription1: "These General Terms of Use (Terms) aim to define the terms of provision of the website and services offered by beTool.fr, as well as the conditions of access and use of these services by users. These Terms govern all relationships between the company and users of its website, whether they are simple visitors or subscribers.",
        objectDescription2: "The use of the website and its services implies the unconditional acceptance of these Terms by the user. If the user does not accept the terms of the Terms, they are invited to immediately leave the website and not use the services offered.",
        objectDescription3: "The company reserves the right to unilaterally and at any time modify the content of these Terms. Therefore, it is recommended that the user regularly consults the latest version of the Terms available on the website.",
        objectDescription4: "The Terms do not create any partnership, agency, franchise, or employment relationship between the company and the user.",

        conditionsTitle: "4. Terms of Use",
        conditionsDescription1: "The User agrees to use the services offered by the Supplier in good faith and in accordance with these Terms, applicable regulations, and practices.",
        conditionsDescription2: "Access to the services offered by the Supplier is reserved for individuals who are legally capable of entering into contracts under French law.",
        conditionsDescription3: "The User is solely responsible for the use of the services offered by the Supplier and the data they store, transmit, or receive in the course of these services. The User agrees not to violate public order, morality, the rights of third parties, or the security of the Supplier.",
        conditionsDescription4: "The User agrees to provide accurate, complete, and up-to-date information when registering for the services offered by the Supplier. They undertake to inform the Supplier promptly of any changes to this information.",
        conditionsDescription5: "The Supplier reserves the right to suspend or terminate access to the services offered in the event of non-compliance with these Terms or violation of applicable regulations.",

        accessTitle: "5. Access to the Site",
        accessDescription1: "The website https://betool.fr allows the User to access the following services:",
        accessService1: "Software/application/platform SaaS provider",
        accessService2: "Purchase or rental of user licenses",
        accessService3: "Purchase or rental of application licenses available on the platform",
        accessService4: "Product category (Work system, Work OS, CRM, ERP, Project Management, and Ticketing solution)",
        accessService5: "Product type: noCode.",
        accessDescription2: "The site is accessible free of charge from anywhere to any User with Internet access. All costs incurred by the User to access the service (computer hardware, software, internet connection, etc.) are their responsibility.",
        accessDescription3: "Non-member Users do not have access to the reserved services. To do so, they must register by filling out the form. By agreeing to register for the reserved services, member Users undertake to provide truthful and accurate information regarding their identity and contact details, including their email address. To access the services, the User must then log in using their username and password, which will be provided to them after registration. Any regularly registered member User may also request their unsubscribe by visiting the dedicated page on their personal space. This will take effect within a reasonable time.",
        accessDescription4: "Any event resulting from a case of force majeure resulting in a malfunction of the site or server and subject to any interruption or modification for maintenance purposes does not engage the responsibility of https://betool.fr. In such cases, the User agrees not to hold the editor liable for any interruption or suspension of service, even without prior notice.",
        accessDescription5: "The User has the possibility to contact the site by email at the editor's email address provided in ARTICLE 1.",

        intellectualPropertyTitle: "6. Intellectual Property",
        intellectualPropertyDescription1: "The trademarks, logos, signs, as well as all the content of the site (text, images, sound...) are protected by the Intellectual Property Code, particularly copyright law.",
        intellectualPropertyDescription2: "The User must seek prior authorization from the site for any reproduction, publication, or copying of the various contents. They undertake to use the site's content strictly for private purposes, and any use for commercial and advertising purposes is strictly prohibited.",
        intellectualPropertyDescription3: "Any total or partial representation of this site by any means whatsoever, without the express permission of the website operator, would constitute an infringement punishable under articles L 335-2 and following of the Intellectual Property Code.",
        intellectualPropertyDescription4: "It is reminded in accordance with Article L122-5 of the Intellectual Property Code that the User who reproduces, copies, or publishes protected content must cite the author and their source.",


        privacyTitle: "7. Privacy and Data Protection",
        privacyDescription1: "We place great importance on the confidentiality and protection of our customers' data. As a provider of data management services, we collect and process personal data of our customers.",
        privacyCollectDataTitle: "Data Collection",
        privacyCollectDataDescription: "We take the privacy and protection of your personal data very seriously. We only collect information necessary for the provision of our services, and we never share this information with third parties without your prior consent. When you use our services, we may collect information such as your name, email address, IP address, location, etc. This information is used to personalize and improve your user experience, as well as to respond to your requests and needs.",
        privacyUseDataTitle: "Use of Data",
        privacyUseDataDescription: "We commit to using your personal data only for the purpose of providing our services, and we will not use it for commercial or advertising purposes without your prior consent. We do not sell your data to third parties, and we only share it with third-party service providers who assist us in providing our services (e.g., cloud storage providers). We may also use your data to send you communications related to our services, such as updates, promotions, etc.",
        privacyDataStorageTitle: "Data Storage and Security",
        privacyDataStorageDescription: "We take reasonable security measures to protect your personal data against unauthorized access, use, alteration, or disclosure. We use encryption technologies, firewalls, passwords, and access controls to protect your personal data. We store your data in secure data centers and continuously monitor our infrastructure for any suspicious activity.",
        privacyUserRightsTitle: "User Rights",
        privacyUserRightsDescription: "In accordance with applicable data protection laws, you have the right to request access, rectification, deletion, and portability of your personal data. You can also request the limitation of processing of your personal data or object to their processing for direct marketing purposes. We are committed to responding promptly and effectively to all requests from our users regarding their personal data.",
        privacyDataRetentionTitle: "Data Retention Period",
        privacyDataRetentionDescription: "We only retain your personal data for the period necessary for the provision of our services or for legal or regulatory purposes. We securely destroy your personal data when we no longer need it or when you request us to do so.",
        privacyPolicyLink: "You can view the dedicated privacy policy page HERE",

        userPublicationTitle: "8. User Publication",
        userPublicationDescription1: "The site allows members to publish the following content:",
        userPublicationTemplate: "Template",
        userPublicationTextContent: "textual content",
        userPublicationPhotoVideo: "photo (image) or video",
        userPublicationDescription2: "In their publications, the member agrees to comply with the rules of Netiquette (internet etiquette) and the applicable laws.",
        userPublicationDescription3: "The site may moderate the publications and reserves the right to refuse their publication without having to justify it to the member.",
        userPublicationDescription4: "The member retains full intellectual property rights to their content. However, by publishing a publication on the site, they grant the publishing company a non-exclusive and free right to represent, reproduce, adapt, modify, distribute, and disseminate their publication, directly or through an authorized third party, worldwide, on any medium (digital or physical), for the duration of the intellectual property rights. The member grants, in particular, the right to use their publication on the internet and mobile telephony networks.",
        userPublicationDescription5: "The publishing company undertakes to display the member's name next to each use of their publication.",
        userPublicationDescription6: "Any content uploaded by the User is their sole responsibility. The User agrees not to upload content that may infringe the interests of third parties. Any legal action brought against the site by an aggrieved third party will be the responsibility of the User.",
        userPublicationDescription7: "The content uploaded by the User may be removed or modified by the site at any time and for any reason, without notice.",

        responsibilityTitle: "9. Liability",
        responsibilityDescription1: "The sources of the information published on the www.betool.fr website are deemed reliable, but the site does not guarantee that it is free of defects, errors, or omissions. The information provided is indicative and general in nature and does not have contractual value. Despite regular updates, the www.betool.fr site cannot be held responsible for any changes in administrative and legal provisions that may occur after publication. Likewise, the site cannot be held responsible for the use and interpretation of the information contained on this site.",
        responsibilityDescription2: "The User must keep their password confidential. Any disclosure of the password, in any form, is prohibited. The User assumes the risks associated with the use of their identifier and password. The site disclaims all responsibility.",
        responsibilityDescription3: "The www.betool.fr site cannot be held responsible for any viruses that may infect the User's computer or any computer equipment as a result of use, access, or download from this site. The site's responsibility cannot be engaged in the event of force majeure or the unforeseeable and insurmountable act of a third party.",

        paymentTermsTitle: "10. Payment Terms",
        paymentTermsDescription1: "Payment for our services is made according to the following terms:",
        paymentTermsItem1: "For services subscribed to the purchase of user licenses, payment is made in advance and the amount is based on the number of licenses subscribed. Payment can be made by bank transfer, credit card, or any other payment method accepted by our company.",
        paymentTermsItem2: "For services subscribed to the rental of user licenses, payment is made on a monthly or annual basis, as defined in the rental agreement. Payment can be made by bank transfer, credit card, or any other payment method accepted by our company.",
        paymentTermsItem3: "The configuration assistance services are billed hourly, based on the current rates. Payment can be made by bank transfer, credit card, or any other payment method accepted by our company.",
        paymentTermsDescription2: "All payments must be made within the specified deadlines. In the event of late payment, our company reserves the right to suspend or terminate the respective service, without prejudice to any other rights and remedies that may be exercised. Any payment made after the due date will automatically incur a 10% surcharge on the amount due, without prejudice to other late payment interests.",
        paymentTermsDescription3: "In case of non-payment, our company reserves the right to take any legal action necessary to recover the amounts due.",
        paymentTermsDescription4: "All bank charges related to payments are the responsibility of the client.",

        terminationSuspensionTitle: "11. Termination and Suspension",
        userTerminationTitle: "User Termination",
        userTerminationDescription: "The user may terminate their user agreement at any time by sending a written termination request by registered letter with acknowledgment of receipt to the provider's address. The termination will take effect at the end of the current subscription period, except in case of violation of the terms and conditions, in which case the provider reserves the right to terminate the user agreement immediately without notice or compensation.",
        providerTerminationTitle: "Provider Termination",
        providerTerminationDescription: "The provider reserves the right to terminate the user agreement in case of non-compliance with the terms and conditions by the user. In such cases, the termination will take effect immediately without notice or compensation. The provider may also terminate the user agreement at any time, upon 30 days' notice, without having to justify its decision. In this case, the provider will refund the user the amount corresponding to the remaining subscription period.",
        terminationConsequencesTitle: "Consequences of Termination",
        terminationConsequencesDescription: "In the event of termination of the user agreement, regardless of the reason, the user agrees to immediately cease all use of the service and to return to the provider all documents, data, and information entrusted to them in connection with the use of the service. The provider shall not be liable for any direct or indirect damages caused to the user or any third party as a result of the termination of the user agreement.",
        dataArchivingTitle: "Data Archiving",
        dataArchivingDescription: "Following the termination of the user agreement, the provider undertakes to retain the user's data for a period of [number of days/months/years to be defined] from the date of termination. After this period, the provider may proceed to the permanent deletion of this data, unless the user expressly requests its retention for an additional period. In any case, the provider guarantees the confidentiality and security of the stored data.",

        lawJurisdictionTitle: "12. Applicable Law and Jurisdiction",
        lawApplicableDescription: "These Terms of Use are subject to French law.",
        disputeResolutionDescription: "In the event of a dispute arising between the parties in connection with the execution of these Terms, the parties agree to attempt to settle the dispute amicably. In the absence of an amicable agreement within thirty (30) days from the notification of the dispute by one party to the other, the dispute shall be submitted to the competent courts of Paris, regardless of multiple defendants or third-party proceedings.",
        userFinalDisclaimerDescription: "In the event of a dispute with an end user of the platform, it is reminded that the publishing company is only a technical service provider on behalf of the end user. The publishing company shall not be held liable for any disputes arising between the end user and a third party. Any dispute shall be resolved directly between the end user and the relevant third party, without involving the liability of the publishing company.",
        independenceDisclaimerDescription: "The parties expressly acknowledge that these Terms of Use do not create any community of rights or any mandate or company between the parties, and that each party retains its full and complete independence.",
        waiverDescription: "Any waiver of a right provided for in these Terms of Use shall not be construed as a waiver of any other right or provision at the same time or at any other time.",
        articleTitlesDisclaimerDescription: "The article titles in these Terms of Use are included for convenience only and shall not affect the meaning or interpretation of these Terms of Use.",

        hyperlinkTitle: "13. Hyperlinks",
        hyperlinkDescription: "Hyperlinks may be present on the website. The User is informed that by clicking on these links, they will be leaving the website https://betool.fr. The website has no control over the web pages to which these links lead and cannot be held responsible for their content.",

        cookiesTitle: "14. Cookies",
        cookiesDescription1: "The User is informed that during their visits to the website, a cookie may be automatically installed on their browsing software.",
        cookiesDescription2: "Cookies are small files temporarily stored on the hard drive of the User's computer by their browser and are necessary for the use of the website https://betool.fr. Cookies do not contain personal information and cannot be used to identify anyone. A cookie contains a unique, randomly generated, and therefore anonymous identifier. Some cookies expire at the end of the User's visit, while others remain.",
        cookiesDescription3: "The information contained in cookies is used to improve the website https://betool.fr.",
        cookiesDescription4: "By browsing the website, the User accepts them.",
        cookiesDescription5: "However, the User must give their consent to the use of certain cookies.",
        cookiesDescription6: "In the event of non-acceptance, the User is informed that certain features or pages may be denied to them.",
        cookiesDescription7: "The User can disable these cookies through the settings within their browsing software.",

        // dmcaPolicy
        dmcaPolicyTitle: "DMCA Takedown Policy",
        dmcaPolicyEffectiveDate: "Effective as of 01/01/2023",
        dmcaPolicyDescription1: "Our company respects the Digital Millennium Copyright Act (DMCA) and takes copyright infringement complaints very seriously. If you are a copyright owner or have other intellectual property rights and believe that our website or services infringe upon your rights, we encourage you to contact us immediately.",
        dmcaPolicyDescription2: "To submit a notification of copyright infringement, you must provide a written communication that includes the following information:",
        dmcaPolicyDescription3: "An electronic or physical signature of the person authorized to act on behalf of the copyright owner or other intellectual property rights;",
        dmcaPolicyDescription4: "A description of the copyrighted work or other intellectual property that you claim has been infringed;",
        dmcaPolicyDescription5: "A description of the location on our website or within our services where the allegedly infringing material is located;",
        dmcaPolicyDescription6: "Your address, telephone number, and email address;",
        dmcaPolicyDescription7: "A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner or its agents, or the law;",
        dmcaPolicyDescription8: "A statement by you, under penalty of perjury, that the above information in your notification is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.",
        dmcaPolicyDescription9: "Please send your notification to our designated agent for copyright infringement notifications at the following address: contact@betool.fr.",
        dmcaPolicyDescription10: "Once we receive a compliant notification of copyright infringement as described above, we commit to removing or disabling access to the material in question within a reasonable time. We will also take steps to notify the user who uploaded the content in question of the alleged infringement and provide them with an opportunity to submit a proper DMCA counter-notification. If the user submits a proper response, we may reinstate the reported content.",
        dmcaPolicyDescription11: "We reserve the right to remove any content without notice, at our sole discretion, if we determine that it infringes the intellectual property rights of a third party.",
        dmcaPolicyDescription12: "We encourage all of our users to respect the intellectual property rights of others. If you are a user of our website or services and believe that another user has infringed your intellectual property rights, please contact us immediately.",


        privacyPolicyTitle: "PRIVACY POLICY",
        privacyPolicyEffectiveDate: "Effective as of 01/01/2024",
        privacyPolicyDescription1: "betool.fr",
        privacyPolicyDescription0: "Technician Soft Solution",
        privacyPolicyDescription00 :"Type of site: SaaS Software",
        privacyPolicyDescription2: "The purpose of this privacy policy",
        privacyPolicyDataCollected: "The personal data we will collect",
        privacyPolicyDataUsage: "The usage of the collected data",
        privacyPolicyDataAccess: "Who has access to the collected data",
        privacyPolicyUserRights: "The rights of the users of the site",
        privacyPolicyCookiePolicy: "The cookie policy of the site",
        privacyPolicyDescription3: "This privacy policy operates in conjunction with the terms and conditions of use of our site.",

        applicableLaws: "Applicable laws",
        gdprCompliance: "In accordance with the General Data Protection Regulation (GDPR), this privacy policy is compliant with the following regulations. Personal data must be:",
        legalBasisLawfulness1: "processed lawfully, fairly, and in a transparent manner in relation to the data subject (lawfulness, fairness, transparency);",
        legalBasisLawfulness2: "collected for specified, explicit, and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes shall not be considered incompatible with the initial purposes (purpose limitation);",
        legalBasisLawfulness3: "adequate, relevant, and limited to what is necessary in relation to the purposes for which they are processed (data minimization);",
        legalBasisLawfulness4: "accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay (accuracy);",
        legalBasisLawfulness5: "kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes in accordance with Article 89(1) subject to implementation of the appropriate technical and organizational measures required by the regulation in order to safeguard the rights and freedoms of the data subject (storage limitation);",
        legalBasisLawfulness6: "processed in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical or organizational measures (integrity and confidentiality).",
        californiaPrivacyCompliance: "For residents of the state of California, this privacy policy is intended to comply with the California Consumer Privacy Act (CCPA). In the event of any inconsistencies between this document and the CCPA, the state's legislation will apply. If inconsistencies are identified, we will amend our policy to comply with the relevant law.",

        consent: "Consent",
        consentConditions: "Users agree that by using our site, they consent to:",
        consentDataCollection: "the conditions set out in this privacy policy, and",
        personalDataCollection: "the collection, use, and retention of the data listed in this policy.",
        automaticallyCollectedData: "Personal data we collect",
        automaticallyCollectedDataDescription: "When you visit and use our site, we may automatically collect and store the following information:",
        ipAddress: "IP address",
        location: "Location",
        hardwareSoftwareDetails: "Hardware and software details",
        linksClicked: "Links clicked by a user while using the site",
        contentViewed: "Content that the user views on the site",
        browsingHistoryActions: "Browsing history and actions of our users",
        nonAutomaticallyCollectedData: "Non-automatically collected data",
        nonAutomaticallyCollectedDataDescription: "We may also collect the following data when you perform certain functions on our site:",
        firstNameLastName: "First name and last name",
        age: "Age",
        dateOfBirth: "Date of birth",
        gender: "Gender",
        phoneNumber: "Phone number",
        address: "Address",
        paymentInformation: "Payment information",
        autofillData: "Autofill data",
        dataCollectionMethods: "These data may be collected through the following methods:",
        dataCollectionNote: "Please note that we only collect data that helps us achieve the purpose stated in this privacy policy. We will not collect any additional data without notifying you beforehand.",

        howWeUsePersonalData: "How We Use Personal Data\nThe personal data collected on our site will only be used for the purposes specified in this policy or as indicated on relevant pages of our site. We will not use your data beyond what we disclose.",
        automaticallyCollectedDataUsage: "The data we automatically collect is used for the following purposes:",
        improveUserExperience: "Improving the user experience",
        improveSolution: "Improving the solution",
        keepAccessActionLogs: "Keeping a log of access and actions for historical purposes",
        certifyElectronicSignatures: "Certifying electronic signatures",
        nonAutomaticallyCollectedDataUsage: "The data we collect when the user performs certain functions may be used for the following purposes:",
        viaBrowser: "Via the browser",
        dataSharing: "Who we share personal data with",
        employees: "Employees",
        employeeDisclosure: "We may disclose to any member of our organization the user data that they reasonably need to achieve the purposes set out in this policy.",
        thirdParties: "Third parties",
        thirdPartiesDataUsage: "We may share user data with the following third parties:",
        thirdPartiesDataAccess: "Third parties will not have access to user data beyond what is reasonably necessary to achieve the given purpose.",
        otherDisclosures: "Other disclosures",
        lawRequirement: "if required by law",
        legalProceedings: "if required for any legal proceedings",
        protectLegalRights: "to establish or protect our legal rights",
        businessSale: "to buyers or potential buyers of this company in the event that we seek to sell the company",
        hyperlinkDisclaimer: "If you follow hyperlinks from our site to another site, please note that we are not responsible and have no control over their privacy policies and practices.",
        dataRetention: "How long we retain personal data",
        dataRetentionNote: "We do not retain user data beyond what is necessary to achieve the purposes for which it is collected.",

        howWeProtectPersonalData: "How We Protect Your Personal Data\nTo ensure the protection of your security, we use transport layer security protocol to transmit personal information within our system.\nAll data stored in our system is well secured and only accessible to our employees. Our employees are bound by strict confidentiality agreements, and a violation of this agreement would result in the employee's dismissal.\nWhile we take all reasonable precautions to ensure that our user data is secure and that users are protected, there is always a risk of harm. The Internet as a whole can be, at times, insecure, and therefore, we are unable to guarantee the security of user data beyond what is reasonably practical.",
        transportLayerSecurity: "We use transport layer security protocol to transmit personal information within our system.",
        dataStorageSecurity: "All data stored in our system is well secured and only accessible to our employees. Our employees are bound by strict confidentiality agreements, and a violation of this agreement would result in the employee's dismissal.",
        employeeConfidentialityAgreements: "Our employees are bound by strict confidentiality agreements, and a violation of this agreement would result in the employee's dismissal.",
        internetInsecurity: "While we take all reasonable precautions to ensure that our user data is secure and that users are protected, there is always a risk of harm. The Internet as a whole can be, at times, insecure, and therefore, we are unable to guarantee the security of user data beyond what is reasonably practical.",
        minors: "Minors",
        minorDataCollection: "The GDPR specifies that individuals under the age of 15 are considered minors for the purpose of data collection. Minors must have the consent of a legal guardian for their data to be collected, processed, and used.",
        userRights: "Your rights as a user",
        gdprRights: "Under the GDPR, users have the following rights as data subjects:",
        rightOfAccess: "Right of access",
        rightOfRectification: "Right of rectification",
        rightOfErasure: "Right to erasure",
        rightOfRestriction: "Right to restriction of processing",
        rightOfDataPortability: "Right to data portability",
        rightOfObjection: "Right to object",
        gdprRightsDetails: "You can find more information on these rights in Chapter 3 (Articles 12-23) of the GDPR.",
        modifyDeleteContestData: "How to modify, delete, or contest collected data",
        privacyAgent: "If you would like your information to be removed or modified in any way, please contact our privacy agent here:",

        cookiePolicy: "Cookie Policy",
        cookieExplanation: "A cookie is a small file stored on a user's hard drive by the website. Its purpose is to gather data related to the user's browsing habits.",
        functionalCookies: "Functional cookies",
        functionalCookiesDescription: "We use them to remember any selections you make on our site so they are saved for your future visits.",
        analyticalCookies: "Analytical cookies",
        analyticalCookiesDescription: "This allows us to improve the design and functionality of our site by collecting data on the content you access and engage with while using our site.",
        targetingCookies: "Targeting cookies",
        targetingCookiesDescription: "These cookies collect data on how you use the site and your preferences. This allows us to make the information you see on our site more promotional and targeted to you.",
        cookieSettings: "You can choose to be notified each time a cookie is being transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.",
        thirdPartyCookies: "Third-party cookies We may use third-party cookies on our site to achieve the following goals:",
        enhanceSecurity: "Enhance security",
        modifications: "Modifications",
        modificationsDescription: "This privacy policy may be modified from time to time to maintain compliance with the law and to reflect any changes to our data collection process. We recommend that our users check our policy periodically to ensure they are informed of any updates. If necessary, we may notify users via email of changes to this policy.",
        contactDescription: "If you have any questions to ask us, please feel free to contact us using the following:",


        supportRequ: "Support Request",
        leadRequ: "Information Request",
        companyName: "Company Name :",
        problemDescription: "Description of your support request :",
        problemDescriptionL: "Description of your need:",
        phoneContact: "Phone :",
        emailContact: "Email :",



        // POLITIQUE DE CONFIDENTIALITÉ NEW
        introTitle: "Introduction",
        introContent: "beTool.fr is committed to protecting the privacy of its users (\"users\" or \"you\"). This Privacy Policy (\"Policy\") describes how we collect, use, disclose, transfer, and process your personal information when you use our website, mobile apps, or services (collectively, \"Services\").",
        collectTitle: "1. Personal Information Collection",
        collectContent: "We collect personal information about you when you use our Services, including:",
        infoDirectTitle: "Information you provide directly",
        infoDirectContent: "This includes information you enter into our forms, such as your name, email address, password, postal address, and phone number.",
        infoAutoTitle: "Information collected automatically",
        infoAutoContent: "When you use our Services, we automatically collect certain information about your device and your activity, such as your IP address, web browser, operating system, the date and time of your visit, and the pages you visit. We may also collect information about your device, such as the model, operating system, and unique device settings.",
        useInfoTitle: "2. Use of Personal Information",
        useInfoContent: "We use your personal information for the following purposes:",
        provideServiceTitle: "To provide our Services",
        provideServiceContent: "We use your personal information to provide our Services, including to process your orders, provide customer support, and send you Service-related communications.",
        improveServiceTitle: "To improve our Services",
        improveServiceContent: "We use your personal information to enhance our Services, including developing new features and solving issues.",
        personalizeExperienceTitle: "To personalize your experience",
        personalizeExperienceContent: "We use your personal information to tailor your experience of our Services, including recommending products or services that might be of interest to you.",
        marketingPurposesTitle: "For marketing purposes",
        marketingPurposesContent: "We may use your personal information to send you marketing communications, such as newsletters and promotional offers. You can unsubscribe from these marketing communications at any time by following the instructions in the communications you receive.",
        researchPurposesTitle: "For research purposes",
        researchPurposesContent: "We may use your personal information for research, including to better understand our users and their needs.",
        lawComplianceTitle: "To comply with the law",
        lawComplianceContent: "We may use your personal information to comply with the law, including responding to governmental or judicial requests.",
        shareInfoTitle: "3. Sharing of Personal Information",
        shareInfoContent: "We may share your personal information with the following third parties:",
        serviceProvidersTitle: "Service providers",
        serviceProvidersContent: "We may share your personal information with service providers who help us provide our Services, such as payment processors, hosting providers, and analytics providers.",
        businessPartnersTitle: "Business partners",
        businessPartnersContent: "We may share your personal information with business partners who help us deliver our Services, such as marketing and advertising partners.",
        authorizedThirdPartiesTitle: "Authorized third parties",
        authorizedThirdPartiesContent: "We may share your personal information with authorized third parties, such as governmental or judicial authorities, when necessary to comply with the law or to protect our rights or the rights of third parties.",
        internationalTransfersTitle: "4. International Data Transfers",
        internationalTransfersContent: "Your personal information may be transferred and processed in several countries, including those outside the European Economic Area (EEA). We take all necessary measures to ensure that transfers are legal and secure, using standard contractual clauses approved by the European Commission or other appropriate mechanisms to ensure data protection.",
        dataSecurityTitle: "5. Data Security",
        dataSecurityContent: "We have implemented advanced physical, technical, and administrative security measures to protect your personal information against loss, theft, misuse, unauthorized disclosure, unauthorized access, alteration, and destruction. This includes encryption technologies and security protocols to secure data traveling over our networks.",
        userRightsTitle: "6. Your Rights",
        userRightsContent: "Under the General Data Protection Regulation (GDPR), you have the following rights regarding your personal information:",
        rightOfAccessTitle: "Right of access",
        rightOfAccessContent: "You can request a copy of the personal information we hold about you.",
        rightOfRectificationTitle: "Right of rectification",
        rightOfRectificationContent: "You have the right to ask us to correct any inaccurate information about you.",
        rightOfErasureTitle: "Right of erasure",
        rightOfErasureContent: "You can ask us to erase your personal information from our systems if you believe they are no longer necessary for the purposes for which they were collected, or if you withdraw your consent.",
        rightToRestrictTitle: "Right to restrict processing",
        rightToRestrictContent: "You have the right to request that we limit the processing of your personal information under certain conditions.",
        rightToOpposeTitle: "Right to object",
        rightToOpposeContent: "You have the right to object to certain forms of processing of your personal information by us.",
        dataPortabilityRightTitle: "Right to data portability",
        dataPortabilityRightContent: "You can ask us to transfer your data to another organization or to yourself, in a structured, machine-readable format.",
        cookiesTitleS: "7. Cookies and Similar Tracking Technologies",
        cookiesContent: "We may use cookies and similar tracking technologies (such as web beacons, spy pixels, and device identifiers) to collect and store certain information when you use our app. Cookies are small data files that are stored on your device by a website or mobile app. Similar tracking technologies function like cookies and allow us to track your activity on our app over time.",
        cookiesPreferencesTitle: "To remember your preferences",
        cookiesPreferencesContent: "This enhances your user experience by remembering your preferences, such as language or currency.",
        cookiesUsageTitle: "To analyze usage of our app",
        cookiesUsageContent: "We use cookies and similar tracking technologies to understand how users interact with our app. This helps us improve the app and provide a better user experience.",
        cookiesMarketingTitle: "For marketing purposes",
        cookiesMarketingContent: "We may use cookies and similar tracking technologies to offer you targeted advertisements.",
        cookiesControl: "You have control over cookies: Most browsers and mobile devices allow you to control or disable cookies and similar tracking technologies. Please check your browser or mobile device settings to learn how to manage cookies.",
        cookiesNoteTitle: "Note",
        cookiesNoteContent: "Disabling cookies may limit some features of our app.",
        childProtectionTitle: "8. Child Protection",
        childProtectionContent: "Our app is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13 without prior verifiable consent from their parents or guardians.",
        noSensitiveInfoTitle: "We never request sensitive personal information from children",
        noSensitiveInfoContent: "such as their Social Security number or personal address.",
        noAccountCreationTitle: "We do not allow children to create accounts or post content on our app",
        noAccountCreationContent: "",
        noTrackingTitle: "We do not track children when they use our app",
        noTrackingContent: "",
        noThirdPartyLinksTitle: "We do not provide links to third-party websites that are not suitable for children",
        noThirdPartyLinksContent: "",
        encourageParentalInvolvement: "We encourage parents and guardians to monitor their children's online activity and to discuss online safety with them.",
        dataRetentionTitle: "9. Data Retention",
        dataRetentionContent: "We will retain your personal information for as long as necessary to fulfill the purposes for which they were collected, as described in this Policy, unless a longer retention period is required or permitted by law.",
        policyChangesTitle: "10. Policy Changes",
        policyChangesContent: "We may change this Privacy Policy at any time. Changes will take effect immediately upon their publication on our website or upon being communicated to you via email.",
        contactUsTitle: "11. Contact Us",
        contactUsContent: "If you have any questions about this policy or if you wish to exercise your rights regarding your personal data, please contact us at",
        lawAndJurisdictionTitle: "12. Applicable Law and Jurisdiction",
        lawAndJurisdictionContent: "This Policy is subject to French law. Any dispute related to or arising from this Policy will be resolved in the competent courts in France."


    }
}