<template>
    <div class="pageStructure">
      <div class="container">
          <div class="d-flex" style="height: 100vh">
              <v-card flat height="100%" width="256">
                      <v-navigation-drawer permanent>
                          <v-list-item>
                              <v-list-item-content>
                                  <v-list-item-title class="text-h6">
                                      Tutoriel
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                      En francais
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>

                          <v-divider></v-divider>

                          <v-list dense nav>
                              <v-list-item-subtitle class="mb-3">
                                  Themes
                              </v-list-item-subtitle>
                              <v-list-item  link>
                                  <v-list-item-icon>
                                      <v-icon>mdi mdi-view-dashboard</v-icon>
                                  </v-list-item-icon>

                                  <v-list-item-content>
                                      <v-list-item-title>top</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item  link>
                                  <v-list-item-icon>
                                      <v-icon>mdi mdi-view-dashboard</v-icon>
                                  </v-list-item-icon>

                                  <v-list-item-content>
                                      <v-list-item-title>top</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                      </v-navigation-drawer>
                  </v-card>
              <div class="w-100 h-100">
                  <div class="h-100 w-100" style="box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;border-radius: 4px;">
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "tutoApp"
}
</script>

<style scoped>

</style>