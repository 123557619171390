<template>
    <div id="integrations" class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15"
                     style="background: linear-gradient(rgb(211, 229, 243) 0px, rgba(29, 131, 211, 0));">
                <div class="container">
                    <div class="my-7"><h4 class="text-center w-100"><i
                        class="fa-solid fa-signature titleIcon mr-2"></i>{{ $t('message.SignatureElectronique') }}</h4></div>
                </div>
            </section>
            <div class="container">
                <section class="sectionCRM mt-15 pb-cus-150">
                    <div class="row" style="background-color: transparent !important;">
                        <div class="col-12 col-sm-6 h-100" style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">
                            <div class="d-flex justify-content-center align-items-center" style="background-color: transparent !important;">
                                <div class="mt-5 v-card v-card--flat v-sheet theme--light" style="background-color: transparent !important;">
                                    <div class="v-card__text vCardTxt">{{ $t('message.theFuture') }}</div>
                                    <h4>{{ $t('message.electronicSignature') }}</h4>
                                    <p class="descripH1 mb-0">{{ $t('message.electronicSignatureDescription1') }}</p>
                                    <p class="descripH1 mb-0">{{ $t('message.electronicSignatureDescription2') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 d-flex align-items-center">
                            <div class="d-flex align-items-center justify-content-center w-100 h-100">
                                <img src="https://objectif-crm.com/assets/images/signPaper.jpg" class="imageTextRight" style="max-width: 459px;">
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="d-flex">
                        <div class="p-3 d-none d-md-inline-block">
                            <img src="https://objectif-crm.com/assets/images/perfsign.png" style="max-width: 210px; width: 210px; height: 210px;">
                        </div>
                        <div class="p-3">
                            <h5>{{ $t('message.powerAndPerformance') }}</h5>
                            <i>{{ $t('message.electronicSignaturePower') }}</i>
                            <br><br>
                            <i>{{ $t('message.electronicSignatureFuture') }}</i>
                        </div>
                    </div>
                </section>

                <section class="sectionCRM mt-15 pb-cus-150">
                    <div class="row" style="background-color: transparent !important;">
                        <div class="col-12 col-sm-6 h-100" style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">
                            <div class="d-flex justify-content-center align-items-center" style="background-color: transparent !important;">
                                <div class="mt-5 v-card v-card--flat v-sheet theme--light" style="background-color: transparent !important;">
                                    <div class="v-card__text vCardTxt">{{ $t('message.Avantages') }}</div>
                                    <h4>{{ $t('message.LesAvantagesSignatureElectronique') }}</h4>
                                    <p class="descripH1 mb-0">{{ $t('message.IntegrerSignatureElectroniqueDescription1') }}</p>
                                    <ul class="mt-4">
                                        <li>{{ $t('message.AvantageReductionDelais') }}</li>
                                        <li>{{ $t('message.AvantageAugmentationSecurite') }}</li>
                                        <li>{{ $t('message.AvantageFacilitationGestion') }}</li>
                                        <li>{{ $t('message.AvantageAmeliorationExperience') }}</li>
                                        <li>{{ $t('message.AvantageOptimisationProcessus') }}</li>
                                    </ul>
                                    <p class="descripH1 mb-0">{{ $t('message.IntegrerSignatureElectroniqueDescription2') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 d-flex align-items-center">
                            <div class="d-flex align-items-center justify-content-center w-100 h-100">
                                <img src="https://objectif-crm.com/assets/images/signtop.gif" class="imageTextRight" style="max-width: 459px;">
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section class="my-16 mt-4" style="background-color: rgb(255, 239, 253);">
                <div class="container">
                    <div class="justify-content-center align-items-center">
                        <div class="d-flex align-items-center justify-content-center w-100 h-100">
                            <img src="https://objectif-crm.com/assets/images/signtop.png" class="imageTextRight" style="max-width: 459px;">
                        </div>
                    </div>
                    <h4 class="text-center">{{ $t('message.ContactezNousEnSavoirPlus') }}</h4>
                    <p class="descripH1 mb-0 w-100 text-center" style="max-width: unset;">{{ $t('message.ContactezNousDescription') }}</p>
                    <div class="v-card__actions mt-11 pb-8 justify-content-center align-items-center">
                        <button type="button" class="v-btn v-btn--outlined theme--light v-size--default" style="color: black;"><span class="v-btn__content">{{ $t('message.DemandeRappel') }}</span></button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "esignApp"
}
</script>

<style scoped>

</style>