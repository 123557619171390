<template>
    <div id="myHeader">
<!-- traduit-->
        <div class="d-block d-md-none">
            <v-app-bar color="#ffffff" class="position-fixed" style="z-index: 99">
                <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
                <div @click="navigateToRefresh('/')"><v-img style=" margin: -6px 0px 0 0;" src="https://www.objectif-crm.com/assets/images/logoOCRM.png" width="130"/></div>
                <v-spacer></v-spacer>
<!--                <span class="v-chip theme&#45;&#45;dark v-size&#45;&#45;small red " style="margin-top: -2px; flex-wrap: nowrap;"><span class="v-chip__content ellipsis-class">{{ $t('message.construct') }}</span></span>-->
                <template>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon>
                                <v-img v-if="$i18n.locale === 'fr'" class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/fr.png" />
                                <v-img v-else-if="$i18n.locale === 'en'" class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/en.png" />
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group>
                                <v-list-item  @click="changeLangage('fr')">
                                    <v-list-item-icon style=" min-width: 37px;  margin-right: 21px;">
                                        <v-img class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/fr.png"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Francais</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="changeLangage('en')">
                                    <v-list-item-icon style=" min-width: 37px;  margin-right: 21px;">
                                        <v-img  class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/en.png"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Anglais</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </template>

                <v-btn href="https://application.betool.fr" target="_blank" color="#0777cf" icon><v-icon>fa-regular fa-circle-user</v-icon></v-btn>

            </v-app-bar >
            <v-navigation-drawer v-model="drawer" style="z-index: 999" fixed temporary>
                <v-list nav dense class="h-100" style="flex-direction: column !important; display: flex;">
                    <v-list-item-group active-class="deep-purple--text text--accent-4">
                        <div @click="navigateToRefresh('/')" class="text-center">
                            <v-img style=" margin: 10px 0 10px 0;" src="https://www.objectif-crm.com/assets/images/logoOCRM.png" width="130" />
                        </div>
                        <v-list-item @click="navigateToRefresh('/')">
                            <v-list-item-icon>
                                <v-icon>mdi-home</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.home') }}</v-list-item-title>
                        </v-list-item>

                        <h6 class="mt-2">{{ $t('message.services') }}</h6>
                        <v-list-item @click="navigateToRefresh('tools#services')">
                            <v-list-item-icon>
                                <v-img class="imgSelectionTI" :src="'https://objectif-crm.com/assets/images/servicesNew.png'" aspect-ratio="1" height="29" width="29" contain></v-img>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.applicativeServices') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('tools#application')">
                            <v-list-item-icon>
                                <v-img class="imgSelectionTI" :src="'https://objectif-crm.com/assets/images/appPlug.png'" aspect-ratio="1" height="29" width="29" contain></v-img>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.applicationsPlugins') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('tools#collaborateur')">
                            <v-list-item-icon>
                                <v-img class="imgSelectionTI" :src="'https://objectif-crm.com/assets/images/Utilisateurs.png'" aspect-ratio="1" height="29" width="29" contain></v-img>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.collaboratorManagement') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('tools#projets')">
                            <v-list-item-icon>
                                <v-img class="imgSelectionTI theme--light" :src="'https://objectif-crm.com/assets/images/projets.png'" aspect-ratio="1" height="29" width="29" contain></v-img>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.projectManagement') }}</v-list-item-title>
                        </v-list-item>

                        <h6 class="mt-2">{{ $t('message.features') }}</h6>
                        <v-list-item @click="navigateToRefresh('automation')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fa-regular fa-robot</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.automations') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('integrations')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fa-regular fa-plug</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.integrations') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('vues')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fa-regular fa-eye</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.views') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('esign')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fa-solid fa-signature</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.electronicSignature') }}</v-list-item-title>
                        </v-list-item>
<!--                        <v-list-item @click="navigateToRefresh('esign')">-->
<!--                            <v-list-item-icon class="justify-content-center">-->
<!--                                <v-icon small>fa-solid fa-signature</v-icon>-->
<!--                            </v-list-item-icon>-->
<!--                            <v-list-item-title>{{ $t('message.electronicSignature') }}</v-list-item-title>-->
<!--                        </v-list-item>-->

                        <h6 class="mt-2">{{ $t('message.client') }}</h6>
                        <v-list-item href="https://application.betool.fr" target="_blank">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small color="#0777cf">fa-regular fa-circle-user</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title><span style="color: #0777cf">{{ $t('message.login') }}</span></v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="navigateToRefresh('support')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fad fa-people-carry</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.support') }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="navigateToRefresh('contact')">
                            <v-list-item-icon class="justify-content-center">
                                <v-icon small>fas fa-user-headset</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('message.contact') }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                    <div style="margin-top: auto"></div>
                    <div class="socialLink d-flex">
                        <div class="iconSocial Link d-inline-block">
                            <a target="_blank" href="https://www.facebook.com/people/Be-tool/100092438058112/" spellcheck="false">
                                <i class="fa-brands fa-facebook"></i>
                            </a>
                        </div>
                        <v-spacer />
                        <div class="iconSocial Link d-inline-block">
                            <a target="_blank" href="https://www.linkedin.com/company/objectifcrm/">
                                <i class="fa-brands fa-linkedin"></i>
                            </a>
                        </div>
                        <v-spacer />
                        <div class="iconSocial Link d-inline-block">
                            <a target="_blank" href="https://www.youtube.com/@beTool-jv2mk">
                                <i class="fa-brands fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        <v-btn small text class="w-100 mb-2">{{ $t('message.termsOfUse') }}</v-btn>
                    </div>
<!--                    <span class="v-chip theme&#45;&#45;dark v-size&#45;&#45;small red" style="margin-top: -2px;"><span class="v-chip__content">{{ $t('message.underConstruction') }}</span></span>-->
                </v-list>
            </v-navigation-drawer>
        </div>


<!--non traduit-->
        <div id="downBar" class="top-0 w-100 d-none d-md-block" style="background-color: white !important; z-index:140;">
            <!-- traduit-->
            <div class="HiBar">
                <div class="container pb-0" style="padding-top: 3px !important;">
                    <div class="d-flex pt-1">
<!--                        <v-chip small color="red" style="margin-top: -2px;" dark>{{ $t('message.construct') }}</v-chip>-->
                        <v-spacer></v-spacer>

                        <template>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon small class="mx-1" style="margin-top: -4px;">
                                        <v-img v-if="$i18n.locale === 'fr'" class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/fr.png" />
                                        <v-img v-else-if="$i18n.locale === 'en'" class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/en.png" />
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item-group>
                                        <v-list-item  @click="changeLangage('fr')">
                                            <v-list-item-icon style=" min-width: 37px;  margin-right: 21px;">
                                                <v-img class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/fr.png"></v-img>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Francais</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item @click="changeLangage('en')">
                                            <v-list-item-icon style=" min-width: 37px;  margin-right: 21px;">
                                                <v-img  class="imgTranslate" cover src="https://www.objectif-crm.com/assets/images/en.png"></v-img>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Anglais</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </template>

                        <v-btn text x-small href="https://application.betool.fr/register?acces=new">{{ $t('message.signup') }}</v-btn>
                        <v-btn text x-small href="https://application.betool.fr/register?acces=freetrial">{{ $t('message.freeTrial') }}</v-btn>
                        <v-btn text x-small outlined href="https://application.betool.fr" target="_blank">{{ $t('message.login') }}</v-btn>
                        <v-btn v-if="connected" style="margin-top: -5px;" small href="https://application.betool.fr" target="_blank" color="#0777cf" icon><v-icon>fa-regular fa-circle-user</v-icon></v-btn>
                    </div>
                </div>
            </div>



            <header class="v-sheet theme--light v-toolbar v-app-bar white" data-booted="true" style="height: 64px; margin-top: 0; transform: translateY(0px); left: 0; right: 0; box-shadow: none;">
                <div class="v-toolbar__content" style="height: 64px;">
                    <div class="downBar">
                        <div class="container">
                            <div class="d-flex">
                                <div class="h-100" @click="navigateToRefresh('/')">
                                    <v-img style=" margin: -6px 0px 0 0;" src="https://www.objectif-crm.com/assets/images/logoOCRM.png" width="151" />
                                </div>

                                <div id="header" class="px-2 position-relative">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text class="mx-2 v-size--default" v-bind="attrs" v-on="on">{{ $t('message.tools') }}</v-btn>
                                        </template>
                                        <v-card>
                                            <div class="container">
                                                <div>
                                                    <h5 class="titleHeader m-0 p-4 pb-2">{{ $t('message.toolbox') }}</h5>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="sectionOne m-1 w-100">
                                                        <div @click="navigateToRefresh('tools#services')" tabindex="0" class="proHeader classHeaderProduct v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="imgSelectionT">
                                                                    <v-img class="imgSelectionTI" :src="'https://objectif-crm.com/assets/images/servicesNew.png'" aspect-ratio="1" height="47" width="47" contain></v-img>
                                                                </div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.appServices') }}</div>
                                                                    <div class="v-card__text ellipsisClass">{{ $t('message.manageCommunicateAutomate') }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="lesChips">
                                                                <v-chip x-small class="ml-0 mr-2 chipTexte v-chip--label" color="rgba(186, 186, 253, 0.2)" text-color="primary">{{ $t('message.email') }}</v-chip>
                                                                <v-chip x-small class="ml-0 mr-2 chipTexte v-chip--label" color="rgba(186, 186, 253, 0.2)" text-color="primary">{{ $t('message.documents') }}</v-chip>
                                                                <v-chip x-small class="ml-0 mr-2 chipTexte v-chip--label" color="rgba(186, 186, 253, 0.2)" text-color="primary">{{ $t('message.sms') }}</v-chip>
                                                                <v-chip x-small class="ml-0 mr-2 chipTexte v-chip--label" color="rgba(186, 186, 253, 0.2)" text-color="primary">{{ $t('message.calendar') }}</v-chip>
                                                            </div>
                                                        </div>
                                                        <div @click="navigateToRefresh('tools#application')" tabindex="0" class="comHeader classHeaderProduct v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="imgSelectionT">
                                                                    <v-img class="imgSelectionTI theme--light" :src="'https://objectif-crm.com/assets/images/Utilisateurs.png'" aspect-ratio="1" height="47" width="47" contain></v-img>
                                                                </div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.collaboratorManagement') }}</div>
                                                                    <div class="v-card__text ellipsisClass">{{ $t('message.optimizeTeamsProduction') }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="lesChips">
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                <span class="v-chip__content">{{ $t('message.roles') }}</span>
                                                                </span>
                                                                                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                <span class="v-chip__content">{{ $t('message.permissions') }}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="sectiontwo m-1 w-100">
                                                        <div @click="navigateToRefresh('tools#collaborateur')" tabindex="0"
                                                             class="crmHeader classHeaderProduct v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="imgSelectionT">
                                                                    <v-img class="imgSelectionTI theme--light" :src="'https://objectif-crm.com/assets/images/appPlug.png'" aspect-ratio="1" height="47" width="47" contain></v-img>
                                                                </div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.applicationsPlugins') }}</div>
                                                                    <div class="v-card__text ellipsisClass">{{ $t('message.connectActivateFeatures') }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="lesChips">
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.api') }}</span>
                                                                </span>
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.applications') }}</span>
                                                                </span>
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.synchronization') }}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div @click="navigateToRefresh('tools#projets')" tabindex="0"
                                                             class="proHeader classHeaderProduct v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="imgSelectionT">
                                                                    <v-img class="imgSelectionTI theme--light" :src="'https://objectif-crm.com/assets/images/projets.png'" aspect-ratio="1" height="47" width="47" contain></v-img>
                                                                </div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.projectManagement') }}</div>
                                                                    <div class="v-card__text ellipsisClass">{{ $t('message.createDatabasesDynamicNOCODE') }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="lesChips">
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.crm') }}</span>
                                                                </span>
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.erp') }}</span>
                                                                </span>
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.tickets') }}</span>
                                                                </span>
                                                                <span class="ml-0 mr-2 chipTexte v-chip v-chip--label theme--light v-size--x-small" style="background-color: rgba(186, 186, 253, 0.2); border-color: rgba(186, 186, 253, 0.2);">
                                                                    <span class="v-chip__content">{{ $t('message.tasks') }}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-menu>

                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text class="mx-2 v-size--default" v-bind="attrs" v-on="on">{{ $t('message.features') }}</v-btn>
                                        </template>
                                        <v-card>
                                            <div class="container">
                                                <div><h5 class="titleHeader m-0 p-4 pb-2">{{ $t('message.features') }}</h5></div>
                                                <div class="d-flex">
                                                    <div class="sectionOne row m-0">
                                                        <v-card  @click="navigateToRefresh('automation')" class="col-6 m-0 crmHeader classHeaderProductF">
                                                            <div class="d-flex">
                                                                <div class="fonctionHeader"><i class="fa-regular fa-robot"></i></div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.automations') }}</div>
                                                                    <div class="v-card__text">{{ $t('message.letAutomationsWork') }}</div>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                        <v-card  @click="navigateToRefresh('integrations')" class="col-6 m-0 comHeader classHeaderProductF">
                                                            <div class="d-flex">
                                                                <div class="fonctionHeader"><i class="fa-regular fa-plug"></i></div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.integrations') }}</div>
                                                                    <div class="v-card__text">{{ $t('message.simplifyWorkflow') }}</div>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                        <v-card  @click="navigateToRefresh('vues')" class="col-6 m-0 proHeader classHeaderProductF">
                                                            <div class="d-flex">
                                                                <div class="fonctionHeader"><i class="fa-regular fa-eye"></i></div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.views') }}</div>
                                                                    <div class="v-card__text">{{ $t('message.maximizeProductivity') }}</div>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                        <v-card  @click="navigateToRefresh('esign')" class="col-6 m-0 escHeader classHeaderProductF v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="fonctionHeader"><i class="fa-solid fa-signature"></i></div>
                                                                <div>
                                                                    <div class="v-card__title">{{ $t('message.electronicSignature') }}</div>
                                                                    <div class="v-card__text">{{ $t('message.saveTimeEnvironment') }}</div>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                      <!--                          a traduire-->
                                                      <v-card @click="navigateToRefresh('betoolTrust')" class="col-6 m-0 escHeader classHeaderProductF v-card v-card--link v-sheet theme--light">
                                                            <div class="d-flex">
                                                                <div class="fonctionHeader"><i class="fa-duotone fa-camera-retro"></i></div>
                                                                <div>
                                                                    <div class="v-card__title">
                                                                      Horodatage et géolocalisation de photos
                                                                    </div>
                                                                    <div class="v-card__text">
                                                                      Horodatez et géolocalisez vos photos pour ajouter une preuve irréfutable de leur authenticité
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-menu>


                                    <v-btn text class="mx-2 v-size--default" @click="navigateToRefresh('services')">{{ $t('message.services') }}</v-btn>
                                    <v-btn text class="mx-2 v-size--default" @click="navigateToRefresh('support')">{{ $t('message.support') }}</v-btn>
                                </div>
                                <div class="spacer"></div>
                                <div class="d-flex align-items-center">
                                    <v-btn text class="mx-2 v-size--default" @click="navigateToRefresh('pricing')">{{ $t('message.pricing') }}</v-btn>
                                    <v-btn outlined class="mx-2 v-size--default" style="color: rgb(92, 144, 218); caret-color: rgb(92, 144, 218);" @click="navigateToRefresh('contact')">{{ $t('message.contactUs') }}</v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderApp",
    data:() => ({
        drawer: false,
        connected: false,
    }),
    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },
        changeLangage(lang){
            this.$i18n.locale = lang;
            localStorage.setItem('lang', lang);
        },
    }
}
</script>

<style scoped>

</style>