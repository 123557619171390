import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import toolsApp from "@/components/pages/toolsApp.vue";
import automationApp from "@/components/pages/automationApp.vue";
import vuesApp from "@/components/pages/vuesApp.vue";
import integrationsApp from "@/components/pages/integrationsApp.vue";
import esignApp from "@/components/pages/esignApp.vue";
import servicesApp from "@/components/pages/servicesApp.vue";
import pricingApp from "@/components/pages/pricingApp.vue";
import contactApp from "@/components/pages/contactApp.vue";
import aboutUs from "@/components/pages/aboutUs.vue";
import beTool from "@/components/pages/beTool.vue";
import noCode from "@/components/pages/noCode.vue";
import CGU from "@/components/pages/CGU.vue";
import PolitiqueC from "@/components/pages/politiqueC.vue";
import supportApp from "@/components/pages/supportApp.vue";
import DMCA from "@/components/pages/DMCA.vue";
import tutoApp from "@/components/pages/tutoApp.vue";
import API from "@/components/pages/API.vue";
import betoolTrust from "@/components/pages/betoolTrust.vue";
import '@/assets/custom.css'
import supportType from "@/components/pages/supportType.vue";
// Remplacez ceci par votre propre composant

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'accueil',
            component: HelloWorld
        },
        {
            path: '/tools',
            name: 'tools',
            component: toolsApp
        },
        {
            path: '/automation',
            name: 'automation',
            component: automationApp
        },
        {
            path: '/vues',
            name: 'vues',
            component: vuesApp
        },
        {
            path: '/integrations',
            name: 'integrations',
            component: integrationsApp
        },
        {
            path: '/esign',
            name: 'esign',
            component: esignApp
        },
        {
            path: '/services',
            name: 'services',
            component: servicesApp
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: pricingApp
        },
        {
            path: '/contact',
            name: 'contact',
            component: contactApp
        },
        {
            path: '/aboutus',
            name: 'aboutus',
            component: aboutUs
        },
        {
            path: '/betool',
            name: 'betool',
            component: beTool
        },
        {
            path: '/noCode',
            name: 'noCode',
            component: noCode
        },
        {
            path: '/CGU',
            name: 'CGU',
            component: CGU
        },
        {
            path: '/politiqueConfidentialite',
            name: 'PC',
            component: PolitiqueC
        },
        {
            path: '/support',
            name: 'support',
            component: supportApp
        },
        {
            path: '/DMCA',
            name: 'DMCA',
            component: DMCA
        },
        {
            path: '/tuto',
            name: 'tuto',
            component: tutoApp
        },
        {
            path: '/API',
            name: 'API',
            component: API
        },
        {
            path: '/supportType',
            name: 'supportType',
            component: supportType
        },
        {
            path: '/betoolTrust',
            name: 'betoolTrust',
            component: betoolTrust
        },
        {
            path: '*',
            redirect: '/'
        }
        // Ajoutez d'autres routes ici
    ]
})
