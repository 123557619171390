<template>
    <v-app>
        <v-main>
            <header-app/>
            <router-view></router-view>
<!--            <HelloWorld/>-->
            <footer-app/>
        </v-main>
    </v-app>
</template>

<script>

import headerApp from "@/components/layout/headerApp.vue";
import footerApp from "@/components/layout/footerApp.vue";


export default {
    name: 'App',

    components: {
        headerApp, footerApp

    },

    data: () => ({
        //
    }),
};
</script>
