<template>
    <div >
        <h5>{{ $t('message.frequently_asked_questions') }}</h5>
        <v-expansion-panels multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.how_does_platform_work') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.platform_works_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.do_i_need_to_code_to_use_platform') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.no_coding_needed_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.what_are_the_pricing_plans') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.pricing_plans_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.how_can_i_contact_support_team') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.contact_support_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.how_can_i_integrate_third_party_services') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.integration_with_third_party_services_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.how_can_i_ensure_security_of_my_application') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.application_security_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('message.how_can_i_get_feature_updates_for_my_platform') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('message.feature_updates_description') }}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    name: "frequentlyAsked"
}
</script>

<style scoped>

</style>