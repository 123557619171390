<template>
    <section class="sectionGauche mt-9 pb-15">
        <div class="container">
            <div id="assistPlus" class="row">
                <div class="col-12 col-md-3">
                    <div class="assistanceCubes" @click="navigateToRefresh('supportType#standart')">
                        <img src="https://objectif-crm.com/assets/images/Sstandard.gif" class="w-100">
                        <div class="px-3 pt-2"><h5>{{ $t('message.support_standard') }}</h5>
                            <p>{{ $t('message.support_standard_description') }}</p>
                            <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="assistanceCubes" @click="navigateToRefresh('supportType#premium')">
                        <img src="https://objectif-crm.com/assets/images/Spremium.gif" class="w-100">
                        <div class="px-3 pt-2"><h5>{{ $t('message.support_premium') }}</h5>
                            <p>{{ $t('message.support_premium_description') }}</p>
                            <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="assistanceCubes" @click="navigateToRefresh('supportType#pro')">
                        <img src="https://objectif-crm.com/assets/images/Spro.gif" class="w-100">
                        <div class="px-3 pt-2"><h5>{{ $t('message.support_business') }}</h5>
                            <p>{{ $t('message.support_business_description') }}</p>
                            <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="assistanceCubes" @click="navigateToRefresh('supportType#entreprise')">
                        <img src="https://objectif-crm.com/assets/images/Sbui.gif" class="w-100">
                        <div class="px-3 pt-2"><h5>{{ $t('message.support_enterprise') }}</h5>
                            <p>{{ $t('message.support_enterprise_description') }}</p>
                            <div>{{ $t('message.discover') }} <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "supportStage",
    methods: {

        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },

    },
}
</script>

<style scoped>

</style>