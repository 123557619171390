<template>
  <div>
    <section id="CONFI" class="container" style="padding-top: 120px">
      <div>
        <h1>{{ $t('message.privacyPolicyTitle') }}</h1>
        <p class="text-center mb-15">{{ $t('message.privacyPolicyEffectiveDate', {date: '01/01/2024'}) }}</p>
        <!--          &lt;!&ndash;                <p><span style="color: #0770cf; font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription1') }}</span> <span>By</span> <span style="font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription0') }}</span> </p>&ndash;&gt;-->
        <!--          &lt;!&ndash;                <p style="font-weight: bold;">{{ $t('message.privacyPolicyDescription00') }}</p>&ndash;&gt;-->
        <!--          <p class="titleConfi">{{ $t('message.privacyPolicyDescription2') }}</p>-->
        <!--          <p>{{ $t('message.privacyPolicyDescription25') }}</p>-->
        <!--          <ul>-->
        <!--            <li><span>{{ $t('message.privacyPolicyDataCollected') }}</span></li>-->
        <!--            <li><span>{{ $t('message.privacyPolicyDataUsage') }}</span></li>-->
        <!--            <li><span>{{ $t('message.privacyPolicyDataAccess') }}</span></li>-->
        <!--            <li><span>{{ $t('message.privacyPolicyUserRights') }}</span></li>-->
        <!--            <li><span>{{ $t('message.privacyPolicyCookiePolicy') }}</span></li>-->
        <!--          </ul>-->
        <!--          <p>{{ $t('message.privacyPolicyDescription3') }}</p>-->
      </div>

      <div class="" style="padding-bottom: 75px; border-bottom: 1px solid #ccc">
        <h3><strong>{{ $t('message.introTitle') }}</strong></h3>
        <p>{{ $t('message.introContent') }}</p>

        <p class="titlePC"><strong>{{ $t('message.collectTitle') }}</strong></p>
        <p>{{ $t('message.collectContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.infoDirectTitle') }}:</strong> {{ $t('message.infoDirectContent') }}</li>
          <li><strong>{{ $t('message.infoAutoTitle') }}:</strong> {{ $t('message.infoAutoContent') }}</li>
        </ul>

        <p class="titlePC"><strong>{{ $t('message.useInfoTitle') }}</strong></p>
        <p>{{ $t('message.useInfoContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.provideServiceTitle') }}:</strong> {{ $t('message.provideServiceContent') }}</li>
          <li><strong>{{ $t('message.improveServiceTitle') }}:</strong> {{ $t('message.improveServiceContent') }}</li>
          <li><strong>{{ $t('message.personalizeExperienceTitle') }}:</strong> {{ $t('message.personalizeExperienceContent') }}</li>
          <li><strong>{{ $t('message.marketingPurposesTitle') }}:</strong> {{ $t('message.marketingPurposesContent') }}</li>
          <li><strong>{{ $t('message.researchPurposesTitle') }}:</strong> {{ $t('message.researchPurposesContent') }}</li>
          <li><strong>{{ $t('message.lawComplianceTitle') }}:</strong> {{ $t('message.lawComplianceContent') }}</li>
        </ul>

        <p class="titlePC"><strong>{{ $t('message.shareInfoTitle') }}</strong></p>
        <p>{{ $t('message.shareInfoContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.serviceProvidersTitle') }}:</strong> {{ $t('message.serviceProvidersContent') }}</li>
          <li><strong>{{ $t('message.businessPartnersTitle') }}:</strong> {{ $t('message.businessPartnersContent') }}</li>
          <li><strong>{{ $t('message.authorizedThirdPartiesTitle') }}:</strong> {{ $t('message.authorizedThirdPartiesContent') }}</li>
        </ul>

        <p class="titlePC"><strong>{{ $t('message.internationalTransfersTitle') }}</strong></p>
        <p>{{ $t('message.internationalTransfersContent') }}</p>

        <p class="titlePC"><strong>{{ $t('message.dataSecurityTitle') }}</strong></p>
        <p>{{ $t('message.dataSecurityContent') }}</p>

        <p class="titlePC"><strong>{{ $t('message.userRightsTitle') }}</strong></p>
        <p>{{ $t('message.userRightsContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.rightOfAccessTitle') }}:</strong> {{ $t('message.rightOfAccessContent') }}</li>
          <li><strong>{{ $t('message.rightOfRectificationTitle') }}:</strong> {{ $t('message.rightOfRectificationContent') }}</li>
          <li><strong>{{ $t('message.rightOfErasureTitle') }}:</strong> {{ $t('message.rightOfErasureContent') }}</li>
          <li><strong>{{ $t('message.rightToRestrictTitle') }}:</strong> {{ $t('message.rightToRestrictContent') }}</li>
          <li><strong>{{ $t('message.rightToOpposeTitle') }}:</strong> {{ $t('message.rightToOpposeContent') }}</li>
          <li><strong>{{ $t('message.dataPortabilityRightTitle') }}:</strong> {{ $t('message.dataPortabilityRightContent') }}</li>
        </ul>

        <p class="titlePC"><strong>{{ $t('message.cookiesTitleS') }}</strong></p>
        <p>{{ $t('message.cookiesContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.cookiesPreferencesTitle') }}:</strong> {{ $t('message.cookiesPreferencesContent') }}</li>
          <li><strong>{{ $t('message.cookiesUsageTitle') }}:</strong> {{ $t('message.cookiesUsageContent') }}</li>
          <li><strong>{{ $t('message.cookiesMarketingTitle') }}:</strong> {{ $t('message.cookiesMarketingContent') }}</li>
        </ul>
        <p>{{ $t('message.cookiesControl') }}</p>
        <i><strong>{{ $t('message.cookiesNoteTitle') }}:</strong> {{ $t('message.cookiesNoteContent') }}</i>

        <p class="titlePC"><strong>{{ $t('message.childProtectionTitle') }}</strong></p>
        <p>{{ $t('message.childProtectionContent') }}</p>
        <ul>
          <li><strong>{{ $t('message.noSensitiveInfoTitle') }}:</strong> {{ $t('message.noSensitiveInfoContent') }}</li>
          <li><strong>{{ $t('message.noAccountCreationTitle') }}:</strong> {{ $t('message.noAccountCreationContent') }}</li>
          <li><strong>{{ $t('message.noTrackingTitle') }}:</strong> {{ $t('message.noTrackingContent') }}</li>
          <li><strong>{{ $t('message.noThirdPartyLinksTitle') }}:</strong> {{ $t('message.noThirdPartyLinksContent') }}</li>
        </ul>
        <p>{{ $t('message.encourageParentalInvolvement') }}</p>

        <p class="titlePC"><strong>{{ $t('message.dataRetentionTitle') }}</strong></p>
        <p>{{ $t('message.dataRetentionContent') }}</p>

        <p class="titlePC"><strong>{{ $t('message.policyChangesTitle') }}</strong></p>
        <p>{{ $t('message.policyChangesContent') }}</p>

        <p class="titlePC"><strong>{{ $t('message.contactUsTitle') }}</strong></p>
        <p>{{ $t('message.contactUsContent') }} <a href="mailto:contact@betool.fr">contact@betool.fr</a>.</p>

        <p class="titlePC"><strong>{{ $t('message.lawAndJurisdictionTitle') }}</strong></p>
        <p>{{ $t('message.lawAndJurisdictionContent') }}</p>
      </div>


      <div class="old d-none">
        <div class="pb-10">
          <p class="titleConfi">{{ $t('message.introTitle') }}</p>
          <p>{{ $t('message.introContent') }}</p>

          <p class="titleConfi">{{ $t('message.dataCollectionTitle') }}</p>
          <p>{{ $t('message.dataCollectionContent') }}</p>
          <p class="titleConfi"><i>{{ $t('message.autoCollectDataTitle') }}</i></p>
          <ul>
            <li>{{ $t('message.ipLocation') }}</li>
            <li>{{ $t('message.usageInfo') }}</li>
          </ul>
          <p class="titleConfi"><i>{{ $t('message.manualCollectDataTitle') }}</i></p>
          <ul>
            <li>{{ $t('message.personalInfo') }}</li>
            <li>{{ $t('message.demographicInfo') }}</li>
            <li>{{ $t('message.financialInfo') }}</li>
          </ul>

          <p class="titleConfi">{{ $t('message.useOfDataTitle') }}</p>
          <ul>
            <li>{{ $t('message.serviceOffer') }}</li>
            <li>{{ $t('message.serviceChanges') }}</li>
            <li>{{ $t('message.interactiveFeatures') }}</li>
            <li>{{ $t('message.customerSupport') }}</li>
            <li>{{ $t('message.collectingInfo') }}</li>
            <li>{{ $t('message.monitorUsage') }}</li>
            <li>{{ $t('message.technicalIssues') }}</li>
          </ul>

          <p class="titleConfi">{{ $t('message.dataSharingTitle') }}</p>
          <p>{{ $t('message.dataSharingContent1') }}</p>
          <p>{{ $t('message.dataSharingContent2') }}</p>

          <p class="titleConfi">{{ $t('message.dataSecurityTitle') }}</p>
          <p>{{ $t('message.dataSecurityContent') }}</p>

          <p class="titleConfi">{{ $t('message.userRightsTitle') }}</p>
          <p>{{ $t('message.userRightsContent1') }}</p>
          <p>{{ $t('message.userRightsContent2') }}</p>

          <p class="titleConfi">{{ $t('message.cookiePolicyTitle') }}</p>
          <p>{{ $t('message.cookiePolicyContent') }}</p>

          <p class="titleConfi">{{ $t('message.policyChangesTitle') }}</p>
          <p>{{ $t('message.policyChangesContent') }}</p>

          <p class="titleConfi">{{ $t('message.contactTitle') }}</p>
          <p>{{ $t('message.contactContent') }}</p>
          <ul>
            <li>{{ $t('message.contactEmail') }}</li>
          </ul>
        </div>
      </div>


    </section>


    <!--        <section id="CONFI" class="container" style="padding-top: 120px">-->
    <!--            <div>-->
    <!--                <h1>{{ $t('message.privacyPolicyTitle') }}</h1>-->
    <!--                <p class="text-center mb-15">{{ $t('message.privacyPolicyEffectiveDate', { date: '01/01/2023' }) }}</p>-->
    <!--&lt;!&ndash;                <p><span style="color: #0770cf; font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription1') }}</span> <span>By</span> <span style="font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription0') }}</span> </p>&ndash;&gt;-->
    <!--&lt;!&ndash;                <p style="font-weight: bold;">{{ $t('message.privacyPolicyDescription00') }}</p>&ndash;&gt;-->
    <!--                <p class="titleConfi">{{ $t('message.privacyPolicyDescription2') }}</p>-->
    <!--                <p>{{ $t('message.privacyPolicyDescription25') }}</p>-->
    <!--                <ul>-->
    <!--                    <li><span>{{ $t('message.privacyPolicyDataCollected') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.privacyPolicyDataUsage') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.privacyPolicyDataAccess') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.privacyPolicyUserRights') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.privacyPolicyCookiePolicy') }}</span></li>-->
    <!--                </ul>-->
    <!--                <p>{{ $t('message.privacyPolicyDescription3') }}</p>-->
    <!--            </div>-->

    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.applicableLaws') }}</p>-->
    <!--                <p v-html="$t('message.gdprCompliance')"></p>-->
    <!--                <ul>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness1') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness2') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness3') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness4') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness5') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.legalBasisLawfulness6') }}</span></li>-->
    <!--                </ul>-->
    <!--&lt;!&ndash;                <p>{{ $t('message.californiaPrivacyCompliance') }}</p>&ndash;&gt;-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.consent') }}</p>-->
    <!--                <p>{{ $t('message.consent1') }}</p>-->
    <!--                <ul>-->
    <!--                    <li><span>{{ $t('message.consentConditions') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.consentDataCollection') }}</span></li>-->
    <!--                </ul>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.personalDataCollection') }}</p>-->
    <!--                <div>-->
    <!--                    <div>-->
    <!--                        <p>{{ $t('message.automaticallyCollectedData') }}</p>-->
    <!--                        <p>{{ $t('message.automaticallyCollectedDataDescription') }}</p>-->
    <!--                        <ul>-->
    <!--                            <li><span>{{ $t('message.ipAddress') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.location') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.hardwareSoftwareDetails') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.linksClicked') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.contentViewed') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.browsingHistoryActions') }}</span></li>-->
    <!--                        </ul>-->
    <!--                    </div>-->
    <!--                    <div>-->
    <!--                        <p class="titleConfi">{{ $t('message.nonAutomaticallyCollectedData') }}</p>-->
    <!--                        <p>{{ $t('message.nonAutomaticallyCollectedDataDescription') }}</p>-->
    <!--                        <ul>-->
    <!--                            <li><span>{{ $t('message.firstNameLastName') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.age') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.dateOfBirth') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.gender') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.email') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.phoneNumber') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.address') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.paymentInformation') }}</span></li>-->
    <!--                            <li><span>{{ $t('message.autofillData') }}</span></li>-->
    <!--                        </ul>-->
    <!--                        <p>{{ $t('message.dataCollectionMethods') }}</p>-->
    <!--                        <p>{{ $t('message.dataCollectionNote') }}</p>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.howWeUsePersonalData') }}</p>-->
    <!--                <p>{{ $t('message.howWeUsePersonalData1') }}</p>-->
    <!--                <div>-->
    <!--                    <p>{{ $t('message.automaticallyCollectedDataUsage') }}</p>-->
    <!--                    <ul>-->
    <!--                        <li><span>{{ $t('message.improveUserExperience') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.improveSolution') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.keepAccessActionLogs') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.certifyElectronicSignatures') }}</span></li>-->
    <!--                    </ul>-->
    <!--                </div>-->
    <!--&lt;!&ndash;                <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <p>{{ $t('message.nonAutomaticallyCollectedDataUsage') }}</p>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <ul>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <li value="1" style="margin-bottom: 0pt;"><span>{{ $t('message.viaBrowser') }}</span></li>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </ul>&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.dataSharing') }}</p>-->
    <!--                <div>-->
    <!--                    <p class="titleConfiP">{{ $t('message.employees') }}</p>-->
    <!--                    <p>{{ $t('message.employeeDisclosure') }}</p>-->
    <!--                </div>-->
    <!--                <div>-->
    <!--                    <p class="titleConfiP">{{ $t('message.thirdParties') }}</p>-->
    <!--&lt;!&ndash;                    <p>{{ $t('message.thirdPartiesDataUsage') }}</p>&ndash;&gt;-->
    <!--                    <p>{{ $t('message.thirdPartiesDataAccess') }}</p>-->
    <!--                </div>-->
    <!--                <div>-->
    <!--                    <p class="titleConfiP">{{ $t('message.otherDisclosures') }}</p>-->
    <!--                    <ul>-->
    <!--                        <li><span>{{ $t('message.lawRequirement') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.legalProceedings') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.protectLegalRights') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.businessSale') }}</span></li>-->
    <!--                    </ul>-->
    <!--                    <p>{{ $t('message.hyperlinkDisclaimer') }}</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.dataRetention') }}</p>-->
    <!--                <p>{{ $t('message.dataRetentionNote') }}</p>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.howWeProtectPersonalData') }}</p>-->
    <!--                <p v-html="$t('message.howWeProtectPersonalData1')"></p>-->
    <!--                <div>-->
    <!--&lt;!&ndash;                    <p>{{ $t('message.transportLayerSecurity') }}</p>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <p>{{ $t('message.dataStorageSecurity') }}</p>&ndash;&gt;-->
    <!--                    <p>{{ $t('message.employeeConfidentialityAgreements') }}</p>-->
    <!--                    <p>{{ $t('message.internetInsecurity') }}</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.minors') }}</p>-->
    <!--                <p>{{ $t('message.minorDataCollection') }}</p>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.userRights') }}</p>-->
    <!--                <div>-->
    <!--                    <p>{{ $t('message.gdprRights') }}</p>-->
    <!--                    <ul>-->
    <!--                        <li><span>{{ $t('message.rightOfAccess') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.rightOfRectification') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.rightOfErasure') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.rightOfRestriction') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.rightOfDataPortability') }}</span></li>-->
    <!--                        <li><span>{{ $t('message.rightOfObjection') }}</span></li>-->
    <!--                    </ul>-->
    <!--                    <p>{{ $t('message.gdprRightsDetails') }}</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.modifyDeleteContestData') }}</p>-->
    <!--                <p>{{ $t('message.privacyAgent') }}</p>-->
    <!--                <p style="text-align: left; margin-left: 20pt;"><a href="mailto:contact@betool.fr">contact@betool.fr</a></p>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.cookiePolicy') }}</p>-->
    <!--                <p>{{ $t('message.cookieExplanation') }}</p>-->
    <!--                <ul>-->
    <!--                    <li><span>{{ $t('message.functionalCookies') }}</span>{{ $t('message.functionalCookiesDescription') }}</li>-->
    <!--                    <li><span>{{ $t('message.analyticalCookies') }}</span>{{ $t('message.analyticalCookiesDescription') }}</li>-->
    <!--                    <li><span>{{ $t('message.targetingCookies') }}</span>{{ $t('message.targetingCookiesDescription') }}</li>-->
    <!--                </ul>-->
    <!--                <p>{{ $t('message.cookieSettings') }}</p>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.thirdPartyCookies') }}</p>-->
    <!--                <p>{{ $t('message.thirdPartyCookies1') }}</p>-->
    <!--                <ul>-->
    <!--                    <li><span>{{ $t('message.improveUserExperience') }}</span></li>-->
    <!--                    <li><span>{{ $t('message.enhanceSecurity') }}</span></li>-->
    <!--                </ul>-->
    <!--            </div>-->
    <!--            <div>-->
    <!--                <p class="titleConfi">{{ $t('message.modifications') }}</p>-->
    <!--                <p>{{ $t('message.modificationsDescription') }}</p>-->
    <!--            </div>-->
    <!--            <div class="mb-10">-->
    <!--                <p class="titleConfi">{{ $t('message.contact') }}</p>-->
    <!--                <p>{{ $t('message.contactDescription') }} <a href="mailto:contact@betool.fr">contact@betool.fr</a></p>-->
    <!--            </div>-->
    <!--            <hr>-->
    <!--        </section>-->
  </div>
</template>

<script>
export default {
  name: "politiqueC"
}
</script>

<style scoped>

</style>