<template>
      <section class="sectionChiffreEvo mt-11 pb-8">
          <div class="stats-section">
<!--              <h2>Notre société en quelques chiffres</h2>-->
              <div class="stat">
                  <div class="chiffreCount"><VueCountUp :endVal="clientOrganisation"/></div>
                  <p><b>🏢</b> Entreprises partenaires</p>
              </div>
              <div class="stat">
                  <div><VueCountUp :endVal="userConnect" /></div>
                  <p><b>🧑‍💼</b> Utilisateurs de la solution</p>
              </div>
              <div class="stat">
                  <div><VueCountUp :endVal="numberOfSatisfiedCustomers" /><span>%</span></div>
                  <p><b>👍</b> Clients satisfaits</p>
              </div>
          </div>
      </section>
</template>

<script>
import VueCountUp from 'vue-countup-v2';

export default {
    name: "chiffreComp",
    components: {
        VueCountUp
    },
    data() {
        return {
            clientOrganisation: 300,
            userConnect: 9500,
            numberOfSatisfiedCustomers: 99,
        }
    }
}
</script>

<style scoped>
.stats-section {
    display: flex;
    justify-content: space-around;
}

.stat {
    text-align: center;
}
</style>
